// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

import { SERVER_URL } from '@constants'

import { cloneDeep } from 'lodash'
import { getStockPrice, getInventoryItemsRequest } from '../../../../utility/Utils'

const SLICE_NAME = 'CLAccessoryItem'
const ENTITY_NAME = 'accessory-item'

export const getItems = createAsyncThunk(`${SLICE_NAME}/getItems`, async (parentId) => {
  const response = await axios.get(`${SERVER_URL}/computer-listing/${ENTITY_NAME}`, { params: { accessoryId: parentId } })
  return { list: response.data, listParams: { parentId } }
})

export const addItem = createAsyncThunk(`${SLICE_NAME}/addItem`, async (item, { dispatch, getState }) => {
  await axios.post(`${SERVER_URL}/computer-listing/${ENTITY_NAME}`, item)
  await dispatch(getItems(getState()[SLICE_NAME].listParams.parentId))
  return item
})

export const updateItem = createAsyncThunk(`${SLICE_NAME}/updateItem`, async (item, { dispatch, getState }) => {
  await axios.patch(`${SERVER_URL}/computer-listing/${ENTITY_NAME}/${item.id}`, item)
  await dispatch(getItems(getState()[SLICE_NAME].listParams.parentId))
  return item
})

export const copyItem = createAsyncThunk(`${SLICE_NAME}/copyItem`, async (item, { dispatch }) => {
  await axios.post(`${SERVER_URL}/computer-listing/${ENTITY_NAME}`, item)
  await dispatch(getItems())
  return item
})

export const deleteItem = createAsyncThunk(`${SLICE_NAME}/deleteItem`, async (id, { dispatch, getState }) => {
  await axios.delete(`${SERVER_URL}/computer-listing/${ENTITY_NAME}/${id}`)
  await dispatch(getItems(getState()[SLICE_NAME].listParams.parentId))
  return id
})

export const refreshAllPrices = createAsyncThunk(`${SLICE_NAME}/refreshAllPrices`, async (id, { dispatch, getState }) => {
  const list = getState()[SLICE_NAME].list

  for (let index = 0; index < list.length; index++) {
    const item = list[index];

    if (item.sku) {
      const response = await axios.post(`${SERVER_URL}/linnworks/auth?q=GetInventoryItems`, getInventoryItemsRequest(item.sku))

      if (response.data.Items.length > 0) {
        const inventoryItem = response.data.Items.find(i => i.SKU === item.sku)

        if (inventoryItem) {
          const newItem = { ...item }
          newItem.value = getStockPrice(inventoryItem.StockValue, inventoryItem.StockLevel) * newItem.multiplyBy

          await axios.patch(`${SERVER_URL}/computer-listing/${ENTITY_NAME}/${newItem.id}`, newItem)
        }
      }
    }
  }

  await dispatch(getItems(getState()[SLICE_NAME].listParams.parentId))

  return null
})

export const toggleSidebar = createAsyncThunk(`${SLICE_NAME}/toggleSidebar`, async (item, { }) => {
  return item
})

export const toggleMoveAccessoryItemModal = createAsyncThunk(`${SLICE_NAME}/toggleMoveAccessoryItemModal`, async (item, { }) => {
  return item
})

export const moveItem = createAsyncThunk(`${SLICE_NAME}/copyItem`, async ({ id, accessoryId, currentAccessoryId }, { dispatch }) => {
  await axios.patch(`${SERVER_URL}/computer-listing/${ENTITY_NAME}/move/${id}`, { accessoryId })
  await dispatch(getItems(currentAccessoryId))
  return item
})

export const changeOrder = createAsyncThunk(`${SLICE_NAME}/changeOrder`, async ({ id, direction, parentId }, { dispatch, getState }) => {
  const list = getState()[SLICE_NAME].list.map(i => ({ id: i.id, order: i.order }))

  const itemIndex = list.findIndex(i => i.id === id)

  if (itemIndex > -1) {
    const newItemIndex = direction === -1 ? itemIndex - 1 : itemIndex + 1
    const item = list[itemIndex]

    if (newItemIndex > -1 && newItemIndex < list.length) {
      list.splice(itemIndex, 1)
      list.splice(newItemIndex, 0, item)

      list.forEach((item, index) => {
        item.order = index
      })

      await axios.put(`${SERVER_URL}/computer-listing/${ENTITY_NAME}/change-order`, list)
      await dispatch(getItems(parentId))
    }
  }

  return item
})

const defaultInitialState = {
  list: [],
  selectedItem: {
    name: '',
    multiplyBy: '1',
    value: '0',
    order: '1'
  },
  sidebarOpen: false,
  moveAccessoryItemModal: {
    isOpen: false,
    item: null
  }
}

export const Slice = createSlice({
  name: SLICE_NAME,
  initialState: cloneDeep(defaultInitialState),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getItems.fulfilled, (state, action) => {
        state.list = action.payload.list
        state.listParams = action.payload.listParams
      })
      .addCase(toggleSidebar.fulfilled, (state, action) => {
        state.sidebarOpen = !state.sidebarOpen
        state.selectedItem = action.payload ? action.payload : cloneDeep(defaultInitialState.selectedItem)
      })
      .addCase(addItem.fulfilled, (state) => {
        state.sidebarOpen = false
      })
      .addCase(updateItem.fulfilled, (state) => {
        state.sidebarOpen = false
      })
      .addCase(toggleMoveAccessoryItemModal.fulfilled, (state, action) => {
        state.moveAccessoryItemModal = {
          isOpen: !state.moveAccessoryItemModal.isOpen,
          item: action.payload
        }
      })
  }
})

export default Slice.reducer
