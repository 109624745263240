// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

import { SERVER_URL } from '@constants'

import { cloneDeep } from 'lodash'

const SLICE_NAME = 'PMStack'
const ENTITY_NAME = 'stack'

export const getItems = createAsyncThunk(`${SLICE_NAME}/getItems`, async ({ pagination, filters }, { getState }) => {
  const response = await axios.get(`${SERVER_URL}/production-management/${ENTITY_NAME}`, { params: { ...(pagination ? pagination : getState()[SLICE_NAME].pagination), ...(filters ? filters : getState()[SLICE_NAME].filters) } })
  return response.data
})

export const toggleSidebar = createAsyncThunk(`${SLICE_NAME}/toggleSidebar`, async (item, { }) => {
  return item
})

export const toggleStackWorkSidebar = createAsyncThunk(`${SLICE_NAME}/toggleStackWorkSidebar`, async (item, { }) => {
  return item
})

export const addItem = createAsyncThunk(`${SLICE_NAME}/addItem`, async (item, { dispatch }) => {
  await axios.post(`${SERVER_URL}/production-management/${ENTITY_NAME}`, item)
  await dispatch(getItems({}))
  return item
})

export const updateItem = createAsyncThunk(`${SLICE_NAME}/updateItem`, async (item, { dispatch }) => {
  await axios.patch(`${SERVER_URL}/production-management/${ENTITY_NAME}/${item.id}`, item)
  await dispatch(getItems({}))
  return item
})

export const deleteItem = createAsyncThunk(`${SLICE_NAME}/deleteItem`, async (id, { dispatch }) => {
  await axios.delete(`${SERVER_URL}/production-management/${ENTITY_NAME}/${id}`)
  await dispatch(getItems({}))
  return item
})

export const importCSVToAddOrders = createAsyncThunk(`${SLICE_NAME}/importCSVToAddOrders`, async ({ stackId, orderNumbers }, { dispatch }) => {
  const request = await axios.patch(`${SERVER_URL}/production-management/${ENTITY_NAME}/csv-to-add-orders/${stackId}`, { orderNumbers })
  await dispatch(getItems({}))
  return request.data
})

export const holdStackWorkByManager = createAsyncThunk(`${SLICE_NAME}/stack-work/hold-by-manager`, async ({ stackWorkId, reason }, { dispatch, getState }) => {
  const request = await axios.post(`${SERVER_URL}/production-management/stack-work/hold-by-manager`, { stackWorkId, reason })
  await dispatch(getItems({}))
  return request.data
})

export const unholdStackWorkByManager = createAsyncThunk(`${SLICE_NAME}/stack-work/unhold-by-manager`, async ({ stackWorkId }, { dispatch, getState }) => {
  const request = await axios.patch(`${SERVER_URL}/production-management/stack-work/unhold-by-manager/${stackWorkId}`)
  await dispatch(getItems({}))
  return request.data
})

export const setRowsPerPage = createAsyncThunk(`${SLICE_NAME}/setRowsPerPage`, async (pageSize) => {
  return pageSize
})

export const setPageNo = createAsyncThunk(`${SLICE_NAME}/setPageNo`, async (pageNo) => {
  return pageNo
})

export const resetPagination = createAsyncThunk(`${SLICE_NAME}/resetPagination`, async (item) => {
  return item
})

export const setListFilters = createAsyncThunk(`${SLICE_NAME}/setListFilters`, async (item) => {
  return item
})

export const resetListFilters = createAsyncThunk(`${SLICE_NAME}/resetListFilters`, async (item) => {
  return item
})

const defaultInitialState = {
  list: [],
  totalList: 0,
  selectedItem: {
    name: '',
    maxUsersComputerPicking: '1',
    maxUsersTechnicianL1: '1',
    maxUsersPackingL1: '1',
    isActive: true,
    order: 1
  },
  sidebarOpen: false,
  stackWorkSidebar: {
    isOpen: false,
    selectedItem: null
  },
  pagination: {
    pageNo: 0,
    pageSize: 25
  },
  filters: {
    search: ''
  }
}

export const Slice = createSlice({
  name: SLICE_NAME,
  initialState: cloneDeep(defaultInitialState),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getItems.fulfilled, (state, action) => {
        state.list = action.payload.results
        state.totalList = action.payload.total

        if (state.stackWorkSidebar.selectedItem) {
          state.stackWorkSidebar.selectedItem = state.list.find(i => i.id === state.stackWorkSidebar.selectedItem.id)
        }
      })
      .addCase(toggleSidebar.fulfilled, (state, action) => {
        state.sidebarOpen = !state.sidebarOpen
        state.selectedItem = action.payload ? action.payload : cloneDeep(defaultInitialState.selectedItem)
      })
      .addCase(addItem.fulfilled, (state) => {
        state.sidebarOpen = false
      })
      .addCase(updateItem.fulfilled, (state) => {
        state.sidebarOpen = false
      })
      .addCase(toggleStackWorkSidebar.fulfilled, (state, action) => {
        state.stackWorkSidebar.isOpen = !state.stackWorkSidebar.isOpen
        state.stackWorkSidebar.selectedItem = action.payload
      })
      .addCase(setRowsPerPage.fulfilled, (state, action) => {
        state.pagination = {
          ...state.pagination,
          pageSize: action.payload,
          pageNo: 0
        }
      })
      .addCase(setPageNo.fulfilled, (state, action) => {
        state.pagination = {
          ...state.pagination,
          pageNo: action.payload
        }
      })
      .addCase(resetPagination.fulfilled, (state) => {
        state.pagination = {
          ...defaultInitialState.pagination
        }
      })
      .addCase(setListFilters.fulfilled, (state, action) => {
        state.filters = {
          ...state.filters,
          [action.payload.name]: action.payload.value
        }
        state.pagination = {
          ...state.pagination,
          pageNo: 0
        }
      })
      .addCase(resetListFilters.fulfilled, (state) => {
        state.filters = {
          ...defaultInitialState.filters
        }
      })
  }
})

export default Slice.reducer
