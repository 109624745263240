// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash'

const SLICE_NAME = 'Shared'

export const toggleAddNoteModal = createAsyncThunk(`${SLICE_NAME}/toggleAddNoteModal`, async (item, { }) => {
  return item
})

export const toggleNoteListModal = createAsyncThunk(`${SLICE_NAME}/toggleNoteListModal`, async (item, { }) => {
  return item
})

const defaultInitialState = {
  noteList: [],
  addNoteModalOpen: false,
  noteListModalOpen: false,
}

export const Slice = createSlice({
  name: SLICE_NAME,
  initialState: cloneDeep(defaultInitialState),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(toggleAddNoteModal.fulfilled, (state, action) => {
        state.addNoteModalOpen = !state.addNoteModalOpen
      })
      .addCase(toggleNoteListModal.fulfilled, (state, action) => {
        state.noteListModalOpen = !state.noteListModalOpen
        state.noteList = action.payload || []
      })
  }
})

export default Slice.reducer
