// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

import { SERVER_URL } from '@constants'

import { cloneDeep } from 'lodash'
import { CL_ACCESSORY_TYPE_OPTIONS } from '../../../../utility/Constants'

const SLICE_NAME = 'CLAccessory'
const ENTITY_NAME = 'accessories'

export const getItems = createAsyncThunk(`${SLICE_NAME}/getItems`, async (parentId) => {
  const response = await axios.get(`${SERVER_URL}/computer-listing/${ENTITY_NAME}`, { params: { accessoryGroupId: parentId } })
  return { list: response.data, listParams: { parentId } }
})

export const addItem = createAsyncThunk(`${SLICE_NAME}/addItem`, async (item, { dispatch, getState }) => {
  await axios.post(`${SERVER_URL}/computer-listing/${ENTITY_NAME}`, item)
  await dispatch(getItems(getState()[SLICE_NAME].listParams.parentId))
  return item
})

export const updateItem = createAsyncThunk(`${SLICE_NAME}/updateItem`, async (item, { dispatch, getState }) => {
  await axios.patch(`${SERVER_URL}/computer-listing/${ENTITY_NAME}/${item.id}`, item)
  await dispatch(getItems(getState()[SLICE_NAME].listParams.parentId))
  return item
})

export const copyItem = createAsyncThunk(`${SLICE_NAME}/copyItem`, async (item, { dispatch }) => {
  await axios.post(`${SERVER_URL}/computer-listing/${ENTITY_NAME}`, item)
  await dispatch(getItems())
  return item
})

export const deleteItem = createAsyncThunk(`${SLICE_NAME}/deleteItem`, async (id, { dispatch, getState }) => {
  await axios.delete(`${SERVER_URL}/computer-listing/${ENTITY_NAME}/${id}`)
  await dispatch(getItems(getState()[SLICE_NAME].listParams.parentId))
  return id
})

export const uploadImage = createAsyncThunk(`${SLICE_NAME}/uploadImage`, async ({ id, image }, { dispatch, getState }) => {
  const formData = new FormData()
  formData.append('image', image)

  const response = await axios.patch(`${SERVER_URL}/computer-listing/${ENTITY_NAME}/upload-image/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

  await dispatch(getItems(getState()[SLICE_NAME].listParams.parentId))

  return id
})

export const removeImage = createAsyncThunk(`${SLICE_NAME}/removeImage`, async ({ id }, { dispatch, getState }) => {
  const response = await axios.delete(`${SERVER_URL}/computer-listing/${ENTITY_NAME}/remove-image/${id}`)

  await dispatch(getItems(getState()[SLICE_NAME].listParams.parentId))

  return id
})

export const toggleSidebar = createAsyncThunk(`${SLICE_NAME}/toggleSidebar`, async (item, { }) => {
  return item
})

export const getAccessoryList = createAsyncThunk(`${SLICE_NAME}/getAccessoryList`, async () => {
  const response = await axios.get(`${SERVER_URL}/computer-listing/accessory-group?includeChild=true`)
  return response.data
})

export const changeOrder = createAsyncThunk(`${SLICE_NAME}/changeOrder`, async ({ id, direction, parentId }, { dispatch, getState }) => {
  const list = getState()[SLICE_NAME].list.map(i => ({ id: i.id, order: i.order }))

  const itemIndex = list.findIndex(i => i.id === id)

  if (itemIndex > -1) {
    const newItemIndex = direction === -1 ? itemIndex - 1 : itemIndex + 1
    const item = list[itemIndex]

    if (newItemIndex > -1 && newItemIndex < list.length) {
      list.splice(itemIndex, 1)
      list.splice(newItemIndex, 0, item)

      list.forEach((item, index) => {
        item.order = index
      })

      await axios.put(`${SERVER_URL}/computer-listing/${ENTITY_NAME}/change-order`, list)
      await dispatch(getItems(parentId))
    }
  }

  return item
})

const defaultInitialState = {
  list: [],
  selectedItem: {
    name: '',
    showInDataEntry: false,
    isRequired: true,
    type: CL_ACCESSORY_TYPE_OPTIONS[0],
    settingValues: [],
    order: '1'
  },
  sidebarOpen: false,
  accessoryList: []
}

export const Slice = createSlice({
  name: SLICE_NAME,
  initialState: cloneDeep(defaultInitialState),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getItems.fulfilled, (state, action) => {
        state.list = action.payload.list
        state.listParams = action.payload.listParams
      })
      .addCase(toggleSidebar.fulfilled, (state, action) => {
        state.sidebarOpen = !state.sidebarOpen
        state.selectedItem = action.payload ? action.payload : cloneDeep(defaultInitialState.selectedItem)
      })
      .addCase(addItem.fulfilled, (state) => {
        state.sidebarOpen = false
      })
      .addCase(updateItem.fulfilled, (state) => {
        state.sidebarOpen = false
      })
      .addCase(uploadImage.fulfilled, (state) => {
        state.sidebarOpen = false
      })
      .addCase(removeImage.fulfilled, (state) => {
        state.sidebarOpen = false
      })
      .addCase(getAccessoryList.fulfilled, (state, action) => {
        state.accessoryList = action.payload
      })
  }
})

export default Slice.reducer
