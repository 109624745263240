// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'


// ** Axios Imports
import axios from 'axios'

import { SERVER_URL } from '@constants'

import { cloneDeep } from 'lodash'
import { MARKET_PLACE, PL_PRODUCT_REPRICE_STATUS, PL_USER_ROLE } from '../../../../utility/Constants'

const SLICE_NAME = 'PLProductReprice'
const ENTITY_NAME = 'product-reprice'

export const getCategories = createAsyncThunk(`${SLICE_NAME}/getCategories`, async () => {
  const response = await axios.get(`${SERVER_URL}/parts-listing/category`)
  return response.data
})

export const getSubcategories = createAsyncThunk(`${SLICE_NAME}/getSubcategories`, async (categoryId) => {
  const response = await axios.get(`${SERVER_URL}/parts-listing/sub-category`, { params: { categoryId } })
  return response.data
})

export const getFields = createAsyncThunk(`${SLICE_NAME}/getFields`, async (subcategoryId) => {
  const response = await axios.get(`${SERVER_URL}/parts-listing/field`, { params: { subcategoryId } })
  return response.data
})

export const getItems = createAsyncThunk(`${SLICE_NAME}/getItems`, async (role) => {
  const response = await axios.get(`${SERVER_URL}/parts-listing/${ENTITY_NAME}?role=${role}`)
  return { list: response.data, role }
})

export const getItem = createAsyncThunk(`${SLICE_NAME}/getItem`, async (id) => {
  const response = await axios.get(`${SERVER_URL}/parts-listing/${ENTITY_NAME}/${id}`)
  return response.data
})

export const addItem = createAsyncThunk(`${SLICE_NAME}/addItem`, async (item, { dispatch, getState }) => {
  await axios.post(`${SERVER_URL}/parts-listing/${ENTITY_NAME}?role=${PL_USER_ROLE.REPRICE_CREATOR}`, item)
  await dispatch(getItems(getState()[SLICE_NAME].listRole))
  return item
})

export const updateItem = createAsyncThunk(`${SLICE_NAME}/updateItem`, async (id, { dispatch, getState }) => {
  const selectedItem = { ...getState()[SLICE_NAME].selectedItem }

  let body = {}

  if(selectedItem.type === MARKET_PLACE.AMAZON) {
    body = {
      productAmazonPrice: selectedItem.productAmazonPrice,
    }

  } else if(selectedItem.type === MARKET_PLACE.EBAY) {
    body = {
      productEbayPrice: selectedItem.productEbayPrice,
    }
  }

  await axios.post(`${SERVER_URL}/parts-listing/${ENTITY_NAME}/save/${id}?role=${PL_USER_ROLE.REPRICE_LISTER}`, body)
  return id
})

export const assignItem = createAsyncThunk(`${SLICE_NAME}/assignItem`, async ({ id, role }, { dispatch }) => {
  await axios.post(`${SERVER_URL}/parts-listing/${ENTITY_NAME}/assign/${id}?role=${role}`)
  return id
})

export const submitItem = createAsyncThunk(`${SLICE_NAME}/submitItem`, async ({ id, role }, { dispatch, getState }) => {
  let body = {};

  const selectedItem = { ...getState()[SLICE_NAME].selectedItem }

  if (role === PL_USER_ROLE.REPRICE_REVIEWER && selectedItem.status === PL_PRODUCT_REPRICE_STATUS.UNDER_REVIEW) {

    body = {
      id: selectedItem.id,
      approved: selectedItem.approved,
      rejectionReason: selectedItem.rejectionReason,
    }
  }

  await axios.post(`${SERVER_URL}/parts-listing/${ENTITY_NAME}/submit/${id}?role=${role}`, body)
  return id
})

export const deleteItem = createAsyncThunk(`${SLICE_NAME}/deleteItem`, async (id, { dispatch, getState }) => {
  await axios.delete(`${SERVER_URL}/parts-listing/${ENTITY_NAME}/${id}`)
  await dispatch(getItems(getState()[SLICE_NAME].listRole))
  return id
})

export const toggleSidebar = createAsyncThunk(`${SLICE_NAME}/toggleSidebar`, async (item, { }) => {
  return item
})

export const toggleRepriceSidebar = createAsyncThunk(`${SLICE_NAME}/toggleRepriceSidebar`, async (item, { }) => {
  return item
})

export const updateSelectedItem = createAsyncThunk(`${SLICE_NAME}/updateSelectedItem`, async (item, { }) => {
  return item
})

const defaultInitialState = {
  list: [],
  listRole: null,
  selectedItem: null,
  sidebarOpen: false,
  sidebarRepriceOpen: false,
}

export const Slice = createSlice({
  name: SLICE_NAME,
  initialState: cloneDeep(defaultInitialState),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getCategories.fulfilled, (state, action) => {
        state.categoryList = action.payload
      })
      .addCase(getSubcategories.fulfilled, (state, action) => {
        state.subcategoryList = action.payload
      })
      .addCase(getFields.fulfilled, (state, action) => {
        state.fieldList = action.payload
      })
      .addCase(getItems.fulfilled, (state, action) => {
        state.list = action.payload.list
        state.listRole = action.payload.role
      })
      .addCase(getItem.fulfilled, (state, action) => {
        state.selectedItem = { ...action.payload, productDetails: { ...action.payload.productDetails[0] }, productListings: { ...action.payload.productListings[0] } }
      })
      .addCase(toggleSidebar.fulfilled, (state, action) => {
        state.sidebarOpen = !state.sidebarOpen
        state.selectedItem = action.payload ? action.payload : cloneDeep(defaultInitialState.selectedItem)
      })
      .addCase(toggleRepriceSidebar.fulfilled, (state, action) => {
        state.sidebarRepriceOpen = !state.sidebarRepriceOpen
        state.selectedItem = action.payload ? action.payload : cloneDeep(defaultInitialState.selectedItem)
      })
      .addCase(updateSelectedItem.fulfilled, (state, action) => {
        state.selectedItem = { ...action.payload }
      })
      .addCase(addItem.fulfilled, (state) => {
        state.sidebarOpen = false
      })
  }
})

export default Slice.reducer
