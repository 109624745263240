import { toast } from 'react-toastify'
import { Badge } from 'reactstrap'
import Toast from '../views/shared/Toast'
import { ACTION_TYPES, CL_ACCESSORY_TYPE, CL_USER_ROLE as CL_PRODUCT_ROLE, PARENT_LISTED_SKU_KEYWORD, PERMISSION, PL_PRODUCT_REPRICE_STATUS, PL_USER_ROLE as PRODUCT_REPRICE_ROLE, PL_USER_ROLE as PRODUCT_ROLE, PM_ORDER_IDENTIFIER, PM_ORDER_TYPE, PM_USER_ROLE as PM_ORDER_WORK_ROLE, SERVER_URL, PM_STACK_WORK_STATUS, PM_USER_ROLE, CL_ACCESSORY_USAGE, CL_PRODUCT_LISTING_TYPE, PM_ORDER_REQUEST_STATUS, RESERVE_STATUS, PM_ORDER_WORK_STATUS, PL_USER_ROLE, PL_PRODUCT_STATUS, LINNWORKS_LOCATION } from './Constants'
import XLSX from 'xlsx'
import AMAZON_REGULAR from '@src/assets/images/icons/AMAZON_REGULAR.png'
import AMAZON_PRIME from '@src/assets/images/icons/AMAZON_PRIME.png'
import AMAZON_PREMIUM from '@src/assets/images/icons/AMAZON_PREMIUM.png'
import AMAZON_REPLACEMENT from '@src/assets/images/icons/AMAZON_REPLACEMENT.png'
import AMAZON_BUSINESS from '@src/assets/images/icons/AMAZON_BUSINESS.png'
import moment from 'moment'

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
    const today = new Date()
    return (
        /* eslint-disable operator-linebreak */
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
        /* eslint-enable */
    )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }) => {
    if (!value) return value
    return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
    const date = new Date(value)
    let formatting = { month: 'short', day: 'numeric' }

    if (toTimeForCurrentDay && isToday(date)) {
        formatting = { hour: 'numeric', minute: 'numeric' }
    }

    return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))
export const hasPermission = (permission) => {
    const userData = getUserData()

    let allow = false

    if (userData) {
        allow = userData.userRoles.filter(u => u.role.permissions.filter(p => p.name === permission).length > 0).length > 0
    }

    return allow
}

export const hasPermissions = (permissions) => {
    let allowAll = true

    permissions.forEach(permission => {
        if (!hasPermission(permission)) {
            allowAll = false
        }
    })

    return allowAll
}

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
    if (userRole === 'admin') return '/'
    if (userRole === 'client') return '/access-control'
    return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary25: '#7367f01a', // for option hover bg-color
        primary: '#7367f0', // for selected option bg-color
        neutral10: '#7367f0', // for tags bg-color
        neutral20: '#ededed', // for input border-color
        neutral30: '#ededed' // for input hover border-color
    }
})

export const booleanText = value => (value ? 'Yes' : 'No')

export const booleanBadge = (value, successText, dangerText) => (value ? (
    <Badge color='light-success' pill>
        {successText || 'Yes'}
    </Badge>
) : (
    <Badge color='light-danger' pill>
        {dangerText || 'No'}
    </Badge>
))

export const getSelectedOption = (list, value) => {
    return list.find(c => c.value === value)
}

export const getSelectOptions = (list, key, label, includeFullObject) => {
    return list.map(c => ({ value: c[key], label: typeof label === 'function' ? label(c) : c[label], fullObject: includeFullObject ? c : undefined }))
}

export const getSettingSelectOptions = (settingList, module, key, includeFullObject) => {
    let options = []

    const setting = settingList.find(i => i.module === module && i.key === key)

    if (setting) {
        options = getSelectOptions(setting.settingValues, 'value', 'value', includeFullObject)
    }

    return options
}

export const getAcceptedProductDetails = (productDetails) => {
    return _.find(productDetails, (d) => d.status === 'ACCEPTED')
}

export const getProductStatusBadge = (status) => {
    const color = {
        [PL_PRODUCT_STATUS.NEW]: 'light-primary',
        [PL_PRODUCT_STATUS.IN_PROGRESS]: 'light-secondary',
        [PL_PRODUCT_STATUS.PENDING_REVIEW]: 'light-warning',
        [PL_PRODUCT_STATUS.UNDER_REVIEW]: 'light-info',
        [PL_PRODUCT_STATUS.REJECTED]: 'light-danger',
        [PL_PRODUCT_STATUS.COMPLETED]: 'light-success'
    }

    return <Badge pill color={color[status]} className='me-1'>
        {status}
    </Badge>
}

export const getProductRepriceStatusBadge = (status) => {
    const color = {
        [PL_PRODUCT_REPRICE_STATUS.NEW]: 'light-primary',
        [PL_PRODUCT_REPRICE_STATUS.IN_PROGRESS]: 'light-secondary',
        [PL_PRODUCT_REPRICE_STATUS.PENDING_REVIEW]: 'light-warning',
        [PL_PRODUCT_REPRICE_STATUS.UNDER_REVIEW]: 'light-info',
        [PL_PRODUCT_REPRICE_STATUS.REJECTED]: 'light-danger',
        [PL_PRODUCT_REPRICE_STATUS.COMPLETED]: 'light-success'
    }

    return <Badge pill color={color[status]} className='me-1'>
        {status}
    </Badge>
}

export const getFirst = (list) => {
    return list && list.length > 0 ? list[0] : null
}

export const getStockPrice = (stockValue, stockLevel) => {
    return stockLevel > 0 ? _.round(stockValue / stockLevel, 2) : 0
}

export const needsProductListingAsinSKUSync = (listWithType, accessoryGroupList) => {
    let syncNeeded = false

    const firstProductListing = listWithType.find(i => i.status === 'ACCEPTED')

    if (firstProductListing) {
        const firstProductListingAsin = firstProductListing.productListingAsins[0]

        // Updated product listing asins
        const productListingAsins = listWithType.filter(i => i.status === 'ACCEPTED' && i.id !== firstProductListing.id).map(i => i.productListingAsins[0]).filter(i => i.sku !== firstProductListingAsin.sku || i.stockPrice !== firstProductListingAsin.stockPrice)

        syncNeeded = productListingAsins.length > 0
    }

    return syncNeeded
}

export const hasOldAccessoryPriceInListings = (productListings, accessoryGroupList) => {
    let hasOldPriceInListings = false

    if (productListings) {
        productListings.forEach(productListing => {
            const hasOldPrice = hasOldAccessoryPriceInListingAsin(getFirst(productListing.productListingAsins), accessoryGroupList)

            if (hasOldPrice) {
                hasOldPriceInListings = true
            }
        })
    }

    return hasOldPriceInListings
}

export const hasOldAccessoryPriceInListingAsin = (productListingAsin, accessoryGroupList) => {
    let hasOldPrice = false

    if (productListingAsin) {
        accessoryGroupList.forEach(accessoryGroup => {
            if (accessoryGroup.accessories.filter(a => a.type === CL_ACCESSORY_TYPE.DEFAULT).length > 0) {

                accessoryGroup.accessories.forEach((accessory) => {
                    const accessoryItemsIds = accessory.accessoryItems.map(a => a.id)
                    const selectedProductListingAttribute = productListingAsin.productListingAttributes.find(a => accessoryItemsIds.indexOf(a.accessoryItemId) > -1)

                    const options = getSelectOptions(accessory.accessoryItems, 'id', 'value', true)

                    let selectedOption = null

                    if (selectedProductListingAttribute) {
                        selectedOption = options.find(o => o.value === selectedProductListingAttribute.accessoryItemId)

                        if (selectedOption?.fullObject && selectedProductListingAttribute && selectedOption.fullObject.value != selectedProductListingAttribute.value) {
                            hasOldPrice = true
                        }
                    }
                })
            }
        })
    }

    return hasOldPrice
}

export const getAccessoryItemCurrentValue = (accessoryGroupList, accessoryItemId) => {
    let currentValue = undefined

    accessoryGroupList.forEach(accessoryGroup => {
        accessoryGroup.accessories.forEach(accessory => {
            const accessoryItem = accessory.accessoryItems.find(ai => ai.id === accessoryItemId)

            if (accessoryItem) {
                currentValue = accessoryItem.value
            }
        })
    })

    return currentValue
}

export const recalculateProductListingAsin = (productListingAsin, accessoryGroupList, extra) => {
    const updatedProductListingAttributes = []

    const stockPrice = extra ? extra.stockPrice : productListingAsin.stockPrice

    // Total Machine Cost
    let totalMachineCost = stockPrice
    productListingAsin.productListingAttributes.forEach(item => {
        const oldValue = _.toNumber(item.value)
        const currentValue = getAccessoryItemCurrentValue(accessoryGroupList, item.accessoryItemId)

        if (oldValue !== currentValue) {
            updatedProductListingAttributes.push({ ...item, value: `${currentValue}` })
        }

        totalMachineCost += currentValue
    })
    totalMachineCost = _.round(totalMachineCost, 2)

    // Total Cost
    const totalCost = calculateItemTotalCost(totalMachineCost, productListingAsin.shipping, productListingAsin.packaging)

    // Amazon Selling Price
    const suggestedSellingFees = calculateAmazonFees(productListingAsin.productAmazonPrice.suggestedSellingPrice)
    const suggestedSellingTotalCost = calculateAmazonTotalCost(totalCost, suggestedSellingFees)
    const suggestedSellingProfit = calculateAmazonProfit(productListingAsin.productAmazonPrice.suggestedSellingPrice, suggestedSellingTotalCost)

    // Amazon Minimum Selling Price
    const suggestedMinimumSellingFees = calculateAmazonFees(productListingAsin.productAmazonPrice.suggestedMinimumSellingPrice)
    const suggestedMinimumSellingTotalCost = calculateAmazonTotalCost(totalCost, suggestedMinimumSellingFees)
    const suggestedMinimumSellingProfit = calculateAmazonProfit(productListingAsin.productAmazonPrice.suggestedMinimumSellingPrice, suggestedMinimumSellingTotalCost)

    // Amazon Maximum Selling Price
    const suggestedMaximumSellingFees = calculateAmazonFees(productListingAsin.productAmazonPrice.suggestedMaximumSellingPrice)
    const suggestedMaximumSellingTotalCost = calculateAmazonTotalCost(totalCost, suggestedMaximumSellingFees)
    const suggestedMaximumSellingProfit = calculateAmazonProfit(productListingAsin.productAmazonPrice.suggestedMaximumSellingPrice, suggestedMaximumSellingTotalCost)

    let changes = {
        id: productListingAsin.id,
        totalMachineCost,
        totalCost,
        productListingAttributes: updatedProductListingAttributes,
        productAmazonPrice: {
            id: productListingAsin.productAmazonPrice.id,
            suggestedSellingTotalCost,
            suggestedSellingProfit,
            suggestedMinimumSellingTotalCost,
            suggestedMinimumSellingProfit,
            suggestedMaximumSellingTotalCost,
            suggestedMaximumSellingProfit
        }
    }

    if (extra) {
        changes = { ...changes, ...extra }
    }

    return changes
}

export const getUserFullName = (user) => {
    return user && user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : ''
}

export const calculateItemTotalCost = (totalMachineCost, shipping, packaging) => {
    return _.round(totalMachineCost + shipping + packaging, 2)
}

export const calculateAmazonFees = (amazonSellingPrice) => {
    return _.round(amazonSellingPrice * 0.08, 2)
}

export const calculateAmazonTotalCost = (totalCost, amazonSellingFees) => {
    return _.round(totalCost + amazonSellingFees, 2)
}

export const calculateAmazonProfit = (amazonSellingPrice, amazonSellingTotalCost) => {
    return _.round(amazonSellingPrice - amazonSellingTotalCost, 2)
}

export const renderLinnworksImageUrl = (imageUrl) => {
    return <a href={imageUrl?.replace('tumbnail_', '')} target="_blank"><img src={imageUrl} /></a>
}

export const renderImageUrl = (imageUrl, width) => {
    if (imageUrl) {
        imageUrl = `${SERVER_URL}/${imageUrl}?authorization=${localStorage.accessToken}`
    }

    return <a href={imageUrl} target="_blank"><img width={width} src={imageUrl} /></a>
}

export const renderAmazonTypeIcon = (type) => {
    const availableImages = {
        [PM_ORDER_TYPE.AMAZON_REGULAR]: AMAZON_REGULAR,
        [PM_ORDER_TYPE.AMAZON_PRIME]: AMAZON_PRIME,
        [PM_ORDER_TYPE.AMAZON_REPLACEMENT]: AMAZON_REPLACEMENT,
        [PM_ORDER_TYPE.AMAZON_PREMIUM]: AMAZON_PREMIUM,
        [PM_ORDER_TYPE.AMAZON_BUSINESS]: AMAZON_BUSINESS
    }

    return !!availableImages[type] ? <img style={{ maxWidth: '100%' }} src={availableImages[type]} /> : type
}

export const notifySuccess = (item) => toast.success(<Toast type={'success'} title={'Success'} {...item} />, { icon: false, hideProgressBar: true })
export const notifyError = (item) => toast.error(<Toast type={'danger'} title={'Error'} {...item} />, { icon: false, hideProgressBar: true })

export const getObjectOptions = (a) => {
    return Object.keys(a).map(i => a[i])
}

export const getPermissionsForRole = (role, actionType) => {
    const permissionList = []

    if (actionType === ACTION_TYPES.PARTS_LISTING) {
        if (role === PRODUCT_ROLE.PRODUCT_CREATOR) {
            permissionList.push(PERMISSION.PL_PRODUCT_CREATOR.value)
        } else if (role === PRODUCT_ROLE.PRODUCT_LISTER) {
            permissionList.push(PERMISSION.PL_PRODUCT_LISTER.value)
        } else if (role === PRODUCT_ROLE.PRODUCT_REVIEWER) {
            permissionList.push(PERMISSION.PL_PRODUCT_REVIEWER.value)
        }
    } else if (actionType === ACTION_TYPES.PARTS_LISTING_REPRICE) {
        if (role === PRODUCT_REPRICE_ROLE.REPRICE_CREATOR) {
            permissionList.push(PERMISSION.PL_REPRICE_CREATOR.value)
        } else if (role === PRODUCT_REPRICE_ROLE.REPRICE_LISTER) {
            permissionList.push(PERMISSION.PL_REPRICE_LISTER.value)
        } else if (role === PRODUCT_REPRICE_ROLE.REPRICE_REVIEWER) {
            permissionList.push(PERMISSION.PL_REPRICE_REVIEWER.value)
        }
    } else if (actionType === ACTION_TYPES.COMPUTER_LISTING) {
        if (role === CL_PRODUCT_ROLE.PRODUCT_CREATOR) {
            permissionList.push(PERMISSION.CL_PRODUCT_CREATOR.value)
        } else if (role === CL_PRODUCT_ROLE.PRODUCT_LISTER) {
            permissionList.push(PERMISSION.CL_PRODUCT_LISTER.value)
        }
    } else if (actionType === ACTION_TYPES.PRODUCTION_MANAGEMENT) {
        if (role === PM_ORDER_WORK_ROLE.TECHNICIAN_L1) {
            permissionList.push(PERMISSION.PM_TECHNICIAN_L1.value)
        } else if (role === PM_ORDER_WORK_ROLE.PACKING_L1) {
            permissionList.push(PERMISSION.PM_PACKING_L1.value)
        } else if (role === PM_ORDER_WORK_ROLE.MONITOR_CLEANING) {
            permissionList.push(PERMISSION.PM_MONITOR_CLEANING.value)
        }
    }

    return permissionList
}

export const notAllowedAndRedirect = () => {
    window.location.href = '/'
}

export const getOrderTypeByIdentifier = (identifier) => {
    let orderType = undefined

    if (identifier === PM_ORDER_IDENTIFIER.AMAZON_PREMIUM) {
        orderType = PM_ORDER_TYPE.AMAZON_PREMIUM
    } else if (identifier === PM_ORDER_IDENTIFIER.AMAZON_PRIME) {
        orderType = PM_ORDER_TYPE.AMAZON_PRIME
    } else if (identifier === PM_ORDER_IDENTIFIER.REPLACEMENT) {
        orderType = PM_ORDER_TYPE.AMAZON_REPLACEMENT
    } else if (identifier === PM_ORDER_IDENTIFIER.AMAZON_BUSINESS) {
        orderType = PM_ORDER_TYPE.AMAZON_BUSINESS
    }

    return orderType
}

export const getOrderType = (orderGeneralInfo) => {
    let type = undefined

    if (orderGeneralInfo.Identifiers && orderGeneralInfo.Identifiers.length > 0) {
        type = getOrderTypeByIdentifier(orderGeneralInfo.Identifiers[0].Tag)
    } else if (orderGeneralInfo.Status === 4) {
        type = PM_ORDER_TYPE.RESEND
    } else if (orderGeneralInfo.Source === 'AMAZON' && orderGeneralInfo.SubSource === 'Renewcomp') {
        type = PM_ORDER_TYPE.AMAZON_REGULAR
    } else if (orderGeneralInfo.Source === 'NEWEGG' && orderGeneralInfo.SubSource === 'Renewcomp') {
        type = PM_ORDER_TYPE.NEW_EGG
    } else if (orderGeneralInfo.Source === 'DIRECT') {
        type = PM_ORDER_TYPE.DIRECT
    }

    return type
}

export const getPreviousOrderWorkRole = (role) => {
    let previousRole = null

    if (role === PM_ORDER_WORK_ROLE.TECHNICIAN_L1) {
        previousRole = PM_ORDER_WORK_ROLE.COMPUTER_PICKING
    } else if (role === PM_ORDER_WORK_ROLE.PACKING_L1) {
        previousRole = PM_ORDER_WORK_ROLE.TECHNICIAN_L1
    }

    return previousRole
}

export const getChildComposition = (parentSku, compositeSubItems) => {
    let childComposition = compositeSubItems?.find(s => s.ChannelSKU === parentSku.replace(PARENT_LISTED_SKU_KEYWORD, ''))

    if (!childComposition && compositeSubItems?.length > 0) {
        childComposition = compositeSubItems[0]
    }

    return childComposition
}

export const getStockItemsRequest = (search) => {
    return {
        _url: '/api/Stock/GetStockItems',
        _method: 'GET',
        keyWord: search,
        locationId: LINNWORKS_LOCATION,
        entriesPerPage: 10,
        pageNumber: 1,
        excludeComposites: false,
        excludeVariations: false,
        excludeBatches: false
    }
}

export const getInventoryItemsRequest = (search, exactMatch) => {
    return {
        _url: '/api/Inventory/GetInventoryItems',
        _method: 'GET',
        view: {
            Filters: [{ FilterName: "General", DisplayName: "SKU+/+Title+/+Barcode", FilterNameExact: null, Field: "String", Condition: exactMatch ? "Equals" : "Contains", ConditionDisplayName: exactMatch ? "Equals" : "Contains", Value: search }],
            Columns: [{ ColumnName: "Image" }, { ColumnName: "SKU" }, { ColumnName: "Title" }, { ColumnName: "StockLevel" }, { ColumnName: "StockValue" }]
        },
        stockLocationIds: [LINNWORKS_LOCATION],
        startIndex: 1,
        itemsCount: 200,
        preloadChilds: false,
        isDownload: false
    }
}

export const getOpenOrdersRequest = (search) => {
    return {
        _url: '/api/Orders/GetOpenOrders',
        _method: 'GET',
        fulfilmentCenter: LINNWORKS_LOCATION,
        EntriesPerPage: 999999,
        PageNumber: 1,
        additionalFilter: search
    }
}

export const getIdentifiersByOrderIdsRequest = (orderIds) => {
    return {
        _url: '/api/OpenOrders/GetIdentifiersByOrderIds',
        _method: 'POST',
        OrderIds: orderIds
    }
}

export const getInventoryItemImagesRequest = (inventoryItemId) => {
    return {
        _url: '/api/Inventory/GetInventoryItemImages',
        _method: 'GET',
        inventoryItemId
    }
}

export const getInventoryItemImagesInBulkRequest = (inventoryItemIds) => {
    return {
        _url: '/api/Inventory/GetImagesInBulk',
        _method: 'GET',
        request: {
            StockItemIds: inventoryItemIds
        }
    }
}

export const getGetOrderItemCompositionRequest = (orderId, stockItemId) => {
    return {
        _url: '/api/Orders/GetOrderItemComposition',
        _method: 'GET',
        fulfilmentCenter: LINNWORKS_LOCATION,
        orderId,
        stockItemId
    }
}

export const getCategoriesRequest = (search) => {
    return {
        _url: '/api/Inventory/GetCategories',
        _method: 'GET',
    }
}

export const generateAndDownloadCSV = (csvList) => {
    const link = document.createElement('a')
    document.body.appendChild(link)

    const csvString = csvList.map(e => e.join(",")).join("\n")

    const csvData = new Blob([csvString], { type: 'text/csv' })
    const csvUrl = URL.createObjectURL(csvData)

    link.href = csvUrl
    link.click()

    setTimeout(() => link.remove(), 1000)
}

export const readCSVFile = (file, onDone) => {
    const reader = new FileReader()
    reader.onload = function () {
        const fileData = reader.result
        const wb = XLSX.read(fileData, { type: 'binary' })

        wb.SheetNames.forEach(function (sheetName) {
            onDone(XLSX.utils.sheet_to_row_object_array(wb.Sheets[sheetName]))
        })
    }

    reader.readAsBinaryString(file)
}

export const getDatesDifference = (date1, date2, formatOutput) => {
    const duration = moment.duration(
        moment(date1).diff(
            moment(date2)
        )
    )

    return formatOutput ? `${duration.days()}d ${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s` : duration
}

export const sortCompareString = (value1, value2) => {
    return value1?.localeCompare(value2)
}

export const getStackWorkDuration = (stackWork) => {
    const stackWorkHoldEvent = stackWork.stackWorkHoldEvents.find(sw => !sw.unhold)
    const date1 = stackWork.createdAt
    const date2 = stackWork.status === PM_STACK_WORK_STATUS.COMPLETED ? moment(stackWork.completed) : stackWorkHoldEvent ? moment(stackWorkHoldEvent.hold) : moment()
    let duration = getDatesDifference(date2.format(), date1)

    stackWork.stackWorkHoldEvents.filter(swe => !!swe.unhold).forEach(swe => {
        const stackWorkEventDuration = getDatesDifference(swe.unhold, swe.hold)

        duration = duration.subtract(stackWorkEventDuration)
    })

    return duration
}

export const getAccessoryUsageByRole = role => {
    if ([PM_USER_ROLE.PACKING_L1].indexOf(role) > -1) {
        return [CL_ACCESSORY_USAGE.EXTERNAL_AND_PACKING, CL_ACCESSORY_USAGE.PACKING_ONLY]
    } else if ([PM_USER_ROLE.TECHNICIAN_L1].indexOf(role) > -1) {
        return [CL_ACCESSORY_USAGE.EXTERNAL_AND_PACKING, CL_ACCESSORY_USAGE.EXTERNAL, CL_ACCESSORY_USAGE.INTERNAL]
    } else {
        return [CL_ACCESSORY_USAGE.EXTERNAL, CL_ACCESSORY_USAGE.INTERNAL]
    }
}

export const getProductListingTypeText = (type) => {
    return type === CL_PRODUCT_LISTING_TYPE.AMAZON ? 'Amazon' : type === CL_PRODUCT_LISTING_TYPE.NEW_EGG ? 'New Egg' : ''
}

export const getProductListingAsinFieldText = (type) => {
    return type === CL_PRODUCT_LISTING_TYPE.AMAZON ? 'Asin' : type === CL_PRODUCT_LISTING_TYPE.NEW_EGG ? 'NE Item #' : ''
}

export const requiresAttachMonitor = role => {
    return [PM_USER_ROLE.TECHNICIAN_L1].indexOf(role) > -1
}

export const requiresPackMonitor = role => {
    return [PM_USER_ROLE.PACKING_L1].indexOf(role) > -1
}

export const requiresSerialNumbers = role => {
    return role !== PM_USER_ROLE.COMPUTER_PICKING
}

export const requiresAccessoryItems = role => {
    return role !== PM_USER_ROLE.COMPUTER_PICKING
}

export const requiresTrackingNumber = (role, showTrackingNumber) => {
    return showTrackingNumber && [PM_USER_ROLE.PACKING_L1, PM_USER_ROLE.PACKING_L2].indexOf(role) > -1
}

export const requiresSettingValues = role => {
    return role === PM_USER_ROLE.TECHNICIAN_L1
}

export const isShowSettingValues = (settingValueId, stages, selectedIndex) => {
    if (!settingValueId) {
        return selectedIndex === 0
    }

    return settingValueId === stages[selectedIndex]?.fullObject?.id
}

export const PMSubmitWork = (role, formValues, orderRequests, orderItem, showTrackingNumber, trackingNumber) => {
    let form = null

    orderRequests = orderRequests.filter(or => or.status === PM_ORDER_REQUEST_STATUS.NEW)

    if (orderRequests?.length) {
        notifyError({ message: `This order item contains ${orderRequests.length} open issue(s). You will not be able to submit work for this order item until the issue is closed by your manager.` })
    } else {
        const accessoryItemListArray = formValues.accessoryItemListArray.filter(accessoryItem => !accessoryItem?.isDeleted)

        form = {
            orderId: orderItem.orderId,
            orderItemId: orderItem.id,
            role
        }

        if (requiresSerialNumbers(role)) {
            form.orderWorkSerialNumbers = formValues.serialNumberListArray.map(i => ({ serialNumber: i.value }))
        }

        if (requiresAccessoryItems(role)) {
            form.orderWorkAccessoryItems = accessoryItemListArray.filter(a => a.value === true).map(i => ({ accessoryItemId: i.fieldId, settingValueId: i.settingValueId }))
        }

        if (requiresAttachMonitor(role)) {
            form.attachMonitors = accessoryItemListArray.filter(a => a.hasSerialNumber && a.serialNumber).map(i => ({ serialNumber: i.serialNumber, accessoryItemId: i.fieldId, orderItemId: i.orderItemId }))
        }

        if (requiresPackMonitor(role)) {
            form.packMonitors = accessoryItemListArray.filter(a => a.hasSerialNumber && a.serialNumber && !a.serialNumberDisabled).map(i => ({ serialNumber: i.serialNumber, accessoryItemId: i.fieldId, orderItemId: i.orderItemId }))
        }

        if (requiresTrackingNumber(role, showTrackingNumber) && formValues.trackingNumber && !trackingNumber) {
            form.trackingNumber = formValues.trackingNumber
        }

        if (requiresSettingValues(role)) {
            form.orderWorkSettings = formValues.technicianSettingValueListArray.filter(a => a.value === true).map(i => ({ settingValueId: i.fieldId }))
        }

        return form
    }
}

export const filterPMOrderWorkCompositions = (compositeSubItems) => {
    return compositeSubItems ? compositeSubItems.filter(i => i.CategoryName === 'Computers' && i.ChannelSKU !== 'Zero') : []
}

export const filterPMOrderWorkOrderItems = (role, orderDetails, submittedOrderItems) => {
    const previousOrderWorkRole = getPreviousOrderWorkRole(role)

    // Filter out order items which are already submitted by current role
    let orderItems = orderDetails ? orderDetails.orderItems.filter(oi => !orderDetails.orderWorks.find(ow => ow.orderItemId === oi.id && ow.role === role && ow.status === PM_ORDER_WORK_STATUS.COMPLETED)) : []

    // Filter only for role
    if (previousOrderWorkRole) {
        // Filter out order items to only show which are submitted by previous role
        orderItems = orderItems.filter(oi => orderDetails.orderWorks.find(ow => ow.orderItemId === oi.id && ow.role === previousOrderWorkRole))
    }

    // Filter out the order items which are already submitted
    orderItems = orderItems.filter(o => submittedOrderItems.indexOf(o.id) === -1)

    return orderItems
}

export const getReserveStatusBadge = (status) => {
    const color = {
        [RESERVE_STATUS.NORMAL.value]: 'light-success',
        [RESERVE_STATUS.IN_ORDER.value]: 'light-warning',
        [RESERVE_STATUS.LOW_STOCK.value]: 'light-danger'
    }

    return <Badge pill color={color[status]} className='me-1'>
        {RESERVE_STATUS[status].label}
    </Badge>
}

export const cleanValueForCsv = (value) => {
    value = value || ''

    return value.replaceAll(',', ' ')
}

export const canEditL1PL = (role, status) => {
    return role === PL_USER_ROLE.PRODUCT_CREATOR &&
      (
        !status || [PL_PRODUCT_STATUS.NEW, PL_PRODUCT_STATUS.IN_PROGRESS_L1, PL_PRODUCT_STATUS.REJECTED_L1, PL_PRODUCT_STATUS.COMPLETED].indexOf(status) > -1
      )
}

export const canEditL2PL = () => {

}

export const canReviewL1PL = (role, status) => {
    return role === PL_USER_ROLE.PRODUCT_REVIEWER && status === PL_PRODUCT_STATUS.UNDER_REVIEW_L1
}

export const canReviewL2PL = () => {

}