// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

import { SERVER_URL } from '@constants'

import { cloneDeep } from 'lodash'
import { generateAndDownloadCSV, getDatesDifference, cleanValueForCsv } from '../../../../utility/Utils'
import moment from 'moment'

const SLICE_NAME = 'Report'

export const getOrderReports = createAsyncThunk(`${SLICE_NAME}/getOrderReports`, async ({ userId, role, startDate, endDate }) => {
    const response = await axios.get(`${SERVER_URL}/production-management/order/order-reports`, { params: { userId, role, startDate, endDate } })

    const csvList = [
        [
            "Name",
            "Role",
            "TotalOrders",
            "TotalSubmissions"
        ],
        ...response.data.map(item => [
            `${item.firstName} ${item.lastName}`,
            item.role,
            item.totalOrders,
            item.totalSubmissions
        ])
    ]

    generateAndDownloadCSV(csvList)
})

export const getProductListingReports = createAsyncThunk(`${SLICE_NAME}/getProductListingReports`, async ({ userId, startDate, endDate }) => {
    const response = await axios.get(`${SERVER_URL}/computer-listing/reports/listing-asin`, { params: { userId, startDate, endDate } })

    const accessoryItemHeaders = []
    response.data.map(item => {
        item.productListingAttributes.map(pla => {
            if (!accessoryItemHeaders.find(h => h === pla.accessoryItem.name)) {
                accessoryItemHeaders.push(pla.accessoryItem.name)
            }
        })
    })

    const csvList = [
        [
            userId && "workDifference",
            "type",
            "createdByUser",
            "sku",
            "asin",
            "rank",
            "condition",
            "status",
            "title",
            "createdAt",
            "childSku",
            "totalMachineCost",
            "shipping",
            "packaging",
            "totalCost",
            "imageUrl",
            "listingTitle",
            "stockLevel",
            "stockValue",
            "purchasePrice",
            "productPrice_lowestSellingQtyOffered",
            "productPrice_medianSellingQtyOffered",
            "productPrice_lowestSellingPrice",
            "productPrice_lowestSellingShippingCost",
            "productPrice_lowestSellingTotalPrice",
            "productPrice_medianSellingPrice",
            "productPrice_medianSellingShippingCost",
            "productPrice_medianSellingTotalPrice",
            "productPrice_suggestedSellingPrice",
            "productPrice_suggestedSellingFees",
            "productPrice_suggestedSellingTotalCost",
            "productPrice_suggestedSellingProfit",
            "productPrice_suggestedMinimumSellingPrice",
            "productPrice_suggestedMinimumSellingFees",
            "productPrice_suggestedMinimumSellingTotalCost",
            "productPrice_suggestedMinimumSellingProfit",
            "productPrice_suggestedMaximumSellingPrice",
            "productPrice_suggestedMaximumSellingFees",
            "productPrice_suggestedMaximumSellingTotalCost",
            "productPrice_suggestedMaximumSellingProfit",
            "productPrice_suggestionReason",
            ...accessoryItemHeaders
        ],
        ...response.data.map((item, index) => {
            let workDifference = null

            if (index !== 0 && userId) {
                workDifference = getDatesDifference(item.createdAt, response.data[index - 1].createdAt, true)
            }
            return [
                workDifference,
                item.productListing.type,
                `${item.createdByUser.firstName} ${item.createdByUser.lastName}`,
                item.sku,
                item.asin,
                item.rank,
                item.condition,
                item.status,
                cleanValueForCsv(item.title),
                item.createdAt,
                item.childSku,
                item.totalMachineCost,
                item.shipping,
                item.packaging,
                item.totalCost,
                item.imageUrl,
                cleanValueForCsv(item.listingTitle),
                item.stockLevel,
                item.stockValue,
                item.stockPrice,
                item.productAmazonPrice.lowestSellingQtyOffered,
                item.productAmazonPrice.medianSellingQtyOffered,
                item.productAmazonPrice.lowestSellingPrice,
                item.productAmazonPrice.lowestSellingShippingCost,
                item.productAmazonPrice.lowestSellingTotalPrice,
                item.productAmazonPrice.medianSellingPrice,
                item.productAmazonPrice.medianSellingShippingCost,
                item.productAmazonPrice.medianSellingTotalPrice,
                item.productAmazonPrice.suggestedSellingPrice,
                item.productAmazonPrice.suggestedSellingFees,
                item.productAmazonPrice.suggestedSellingTotalCost,
                item.productAmazonPrice.suggestedSellingProfit,
                item.productAmazonPrice.suggestedMinimumSellingPrice,
                item.productAmazonPrice.suggestedMinimumSellingFees,
                item.productAmazonPrice.suggestedMinimumSellingTotalCost,
                item.productAmazonPrice.suggestedMinimumSellingProfit,
                item.productAmazonPrice.suggestedMaximumSellingPrice,
                item.productAmazonPrice.suggestedMaximumSellingFees,
                item.productAmazonPrice.suggestedMaximumSellingTotalCost,
                item.productAmazonPrice.suggestedMaximumSellingProfit,
                cleanValueForCsv(item.productAmazonPrice.suggestionReason),
                accessoryItemHeaders.map(h => {
                    return item.productListingAttributes.find(pla => pla.accessoryItem.name === h)?.value
                })
            ]
        })
    ]

    generateAndDownloadCSV(csvList)
})

const defaultInitialState = {
}

export const Slice = createSlice({
    name: SLICE_NAME,
    initialState: cloneDeep(defaultInitialState),
    reducers: {},
    extraReducers: builder => {
    }
})

export default Slice.reducer
