// ** Redux Imports
import { SERVER_URL, PM_STACK_WORK_STATUS } from '@constants'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getInventoryItemImagesInBulkRequest, getOpenOrdersRequest, getGetOrderItemCompositionRequest, getOrderType, notifySuccess, notifyError } from '@utils'
// ** Axios Imports
import axios from 'axios'
import { cloneDeep } from 'lodash'

const SLICE_NAME = 'PMOrder'
const ENTITY_NAME = 'order'

export const getOpenOrdersLW = createAsyncThunk(`${SLICE_NAME}/getOpenOrdersLW`, async (orderNumberList) => {
  const response = await axios.post(`${SERVER_URL}/linnworks/auth?q=GetOpenOrders`, getOpenOrdersRequest(orderNumberList.length === 1 ? orderNumberList[0] : ''))

  const inventoryOrderList = []

  if (response.data.Data.length > 0) {
    const orderList = []
    let inventoryItemIdList = []

    orderNumberList.forEach(orderNumber => {
      const inventoryOrder = response.data.Data.find(o => o.NumOrderId == orderNumber)

      if (inventoryOrder) {
        const orderType = getOrderType(inventoryOrder.GeneralInfo)

        if (orderType) {
          orderList.push({
            orderNumber,
            orderType,
            skuList: inventoryOrder.Items.map(inventoryOrderItem => inventoryOrderItem.ChannelSKU)
          })
        }
        inventoryItemIdList = inventoryItemIdList.concat(inventoryOrder.Items.map(inventoryOrderItem => inventoryOrderItem.ItemId))
      }
    })

    const inventoryOrderItemAccessoryResponse = await axios.post(`${SERVER_URL}/production-management/${ENTITY_NAME}/get-accessories`, { orderList })

    const inventoryOrderItemImagesInBulkResponse = await axios.post(`${SERVER_URL}/linnworks/auth?q=GetImagesInBulk`, getInventoryItemImagesInBulkRequest(inventoryItemIdList))


    for (let orderNumberIndex = 0; orderNumberIndex < orderNumberList.length; orderNumberIndex++) {
      const orderNumber = orderNumberList[orderNumberIndex]

      const inventoryOrder = response.data.Data.find(o => o.NumOrderId == orderNumber)

      if (inventoryOrder) {
        inventoryOrder.orderType = getOrderType(inventoryOrder.GeneralInfo)

        const inventoryOrderAccessoryResponse = inventoryOrderItemAccessoryResponse.data.find(o => o.orderNumber == orderNumber)

        const itemsCount = inventoryOrder.Items.length

        for (let index = 0; index < itemsCount; index++) {
          const inventoryOrderItem = inventoryOrder.Items[index]

          inventoryOrderItem._mainImage = inventoryOrderItemImagesInBulkResponse.data.Images.find(i => i.StockItemId === inventoryOrderItem.ItemId && i.IsMain)?.FullSourceThumbnail

          inventoryOrderItem._accessoryItems = inventoryOrderAccessoryResponse?.productListingAttribute ? inventoryOrderAccessoryResponse.productListingAttribute.filter(r => r.productListingAsin.childSku === inventoryOrderItem.ChannelSKU).map(r => r.accessoryItem) : []

          const orderItemLWCompositionsResponse = await axios.post(`${SERVER_URL}/linnworks/auth?q=getGetOrderItemComposition`, getGetOrderItemCompositionRequest(inventoryOrder.OrderId, inventoryOrderItem.ItemId))

          inventoryOrderItem._orderItemLWComposition = orderItemLWCompositionsResponse.data
        }

        const orderItemWithoutAccessoryItems = inventoryOrder.Items.find(i => !i._accessoryItems?.length)

        if (orderItemWithoutAccessoryItems) {
          inventoryOrder._hasErrorMessage = `Order can't be added because ${orderItemWithoutAccessoryItems.ChannelSKU} doesn't have any accessories.`
        } else if (!inventoryOrder.orderType) {
          inventoryOrder._hasErrorMessage = `Order can't be added because order type is not found.`
        }

        inventoryOrderList.push(inventoryOrder)
      } else {
        inventoryOrderList.push({ NumOrderId: orderNumber, _hasErrorMessage: `Order can't be added because it is not found in linnworks.` })
      }
    }
  } else {
    notifyError({ message: `${orderNumberList.length === 1 ? `Order ${orderNumberList[0]}` : 'Orders'} not found in linnworks.` })
  }

  return inventoryOrderList
})

export const getGetOrderItemComposition = createAsyncThunk(`${SLICE_NAME}/getGetOrderItemComposition`, async ({ lwOrderId, lwStockItemId }) => {
  const response = await axios.post(`${SERVER_URL}/linnworks/auth?q=getGetOrderItemComposition`, getGetOrderItemCompositionRequest(lwOrderId, lwStockItemId))
  return response.data
})

export const getAllGetOrderItemComposition = createAsyncThunk(`${SLICE_NAME}/getAllGetOrderItemComposition`, async (orderDetailsList, { dispatch, getState }) => {
  for (const orderDetails of orderDetailsList) {
    for (const orderItem of orderDetails.orderItems) {
      await dispatch(getGetOrderItemComposition({ lwOrderId: orderDetails.lwOrderId, lwStockItemId: orderItem.lwStockItemId }))
    }
  }

  return []
})

export const selectInventoryItem = createAsyncThunk(`${SLICE_NAME}/selectInventoryItem`, async (item) => {
  return item
})

export const getAccessoryList = createAsyncThunk(`${SLICE_NAME}/getAccessoryList`, async () => {
  const response = await axios.get(`${SERVER_URL}/computer-listing/accessory-group?includeChild=true`)
  return response.data
})

export const getCategories = createAsyncThunk(`${SLICE_NAME}/getCategories`, async () => {
  const response = await axios.get(`${SERVER_URL}/parts-listing/category`)
  return response.data
})

export const getSubcategories = createAsyncThunk(`${SLICE_NAME}/getSubcategories`, async (categoryId) => {
  const response = await axios.get(`${SERVER_URL}/parts-listing/sub-category`, { params: { categoryId } })
  return response.data
})

export const getFields = createAsyncThunk(`${SLICE_NAME}/getFields`, async (subcategoryId) => {
  const response = await axios.get(`${SERVER_URL}/production-management/field`, { params: { subcategoryId } })
  return response.data
})

export const getItems = createAsyncThunk(`${SLICE_NAME}/getItems`, async ({ filters, pagination }, { getState }) => {
  // const allFilters = {
  //   ...getState()[SLICE_NAME].activeListFilters,
  //   ...filters
  // }
  const response = await axios.get(`${SERVER_URL}/production-management/${ENTITY_NAME}`, { params: { ...(filters ? filters : getState()[SLICE_NAME].activeListFilters), ...(pagination ? pagination : getState()[SLICE_NAME].pagination) } })
  return { ...response.data }
})

export const getItem = createAsyncThunk(`${SLICE_NAME}/getItem`, async ({ orderNumber, role }, { dispatch, getState }) => {
  const response = await axios.get(`${SERVER_URL}/production-management/${ENTITY_NAME}/search-stack-or-order`, { params: { orderNumber: (orderNumber || '').trim(), role } })
  dispatch(getAllGetOrderItemComposition(response.data))
  return response.data
})

export const searchItem = createAsyncThunk(`${SLICE_NAME}/searchItem`, async ({ orderNumber }) => {
  const response = await axios.get(`${SERVER_URL}/production-management/${ENTITY_NAME}/search-order`, { params: { orderNumber } })
  const order = response.data

  const skuList = order.orderItems.map(oi => oi.sku)

  const responseListingAsinsBySkus = await axios.post(`${SERVER_URL}/production-management/${ENTITY_NAME}/get-listing-asins-by-skus`, { skuList })

  const listingAsinsBySkus = responseListingAsinsBySkus.data

  return { order, listingAsinsBySkus }
})

export const setAddItemsLoading = createAsyncThunk(`${SLICE_NAME}/setAddItemsLoading`, async (loading) => {
  return loading
})

export const addItems = createAsyncThunk(`${SLICE_NAME}/addItems`, async ({ orders }, { dispatch, getState }) => {
  await axios.post(`${SERVER_URL}/production-management/${ENTITY_NAME}`, orders)
  await dispatch(getItems({ type: getState()[SLICE_NAME].listType }))
  return orders
})

export const saveItem = createAsyncThunk(`${SLICE_NAME}/saveItem`, async ({ item }, { dispatch, getState }) => {
  const response = await axios.post(`${SERVER_URL}/production-management/${ENTITY_NAME}/add-order-work`, item)
  await dispatch(getItems({ type: getState()[SLICE_NAME].listType }))
  return { orderItemId: item.orderItemId, order: response.data.order }
})

export const saveItemAsDraft = createAsyncThunk(`${SLICE_NAME}/saveItemAsDraft`, async ({ item }, { dispatch, getState }) => {
  const response = await axios.post(`${SERVER_URL}/production-management/${ENTITY_NAME}/add-order-work`, item)
  await dispatch(getItems({ type: getState()[SLICE_NAME].listType }))
  return { order: response.data.order }
})

export const addNote = createAsyncThunk(`${SLICE_NAME}/addNote`, async ({ note }, { dispatch }) => {
  return note
})

export const switchToPrimeItem = createAsyncThunk(`${SLICE_NAME}/switchToPrimeItem`, async ({ id }, { dispatch, getState }) => {
  await axios.patch(`${SERVER_URL}/production-management/${ENTITY_NAME}/switch-to-prime/${id}`)
  await dispatch(getItems({ type: getState()[SLICE_NAME].listType }))
  return id
})

export const holdItem = createAsyncThunk(`${SLICE_NAME}/holdItem`, async ({ id, hold, reason }, { dispatch, getState }) => {
  await axios.patch(`${SERVER_URL}/production-management/${ENTITY_NAME}/hold/${id}`, { hold, reason })
  await dispatch(getItems({ type: getState()[SLICE_NAME].listType }))
  return id
})

export const submitItem = createAsyncThunk(`${SLICE_NAME}/submitItem`, async ({ id, productListingId, role }, { dispatch, getState }) => {
  const body = {}

  let url = `${SERVER_URL}/production-management/${ENTITY_NAME}/submit/${id}?role=${role}`

  if (productListingId) {
    url += `&productListingIds[]=${productListingId}`
  }

  await axios.patch(url, body)
  await dispatch(getItems({ type: getState()[SLICE_NAME].listType }))
  return id
})

export const deleteItem = createAsyncThunk(`${SLICE_NAME}/deleteItem`, async (id, { dispatch, getState }) => {
  await axios.delete(`${SERVER_URL}/production-management/${ENTITY_NAME}/${id}`)
  await dispatch(getItems({ type: getState()[SLICE_NAME].listType }))
  return id
})

export const forceDeleteItem = createAsyncThunk(`${SLICE_NAME}/forceDeleteItem`, async ({ id, deleteOrder }, { dispatch, getState }) => {
  await axios.get(`${SERVER_URL}/production-management/${ENTITY_NAME}/force-delete/${id}?deleteOrder=${deleteOrder}`)
  await dispatch(getItems({ type: getState()[SLICE_NAME].listType }))
  return id
})

export const uploadImage = createAsyncThunk(`${SLICE_NAME}/uploadImage`, async ({ id, image }, { dispatch, getState }) => {
  const formData = new FormData()
  formData.append('image', image)

  const response = await axios.post(`${SERVER_URL}/production-management/${ENTITY_NAME}/upload-image/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

  return response.data
})

export const removeImage = createAsyncThunk(`${SLICE_NAME}/removeImage`, async (id, { dispatch, getState }) => {
  return id
})

export const toggleSidebar = createAsyncThunk(`${SLICE_NAME}/toggleSidebar`, async (item, { }) => {
  return item
})

export const toggleAddExtendableAccessoryModal = createAsyncThunk(`${SLICE_NAME}/toggleAddExtendableAccessoryModal`, async (orderItem, { }) => {
  return orderItem
})

export const updateSelectedItem = createAsyncThunk(`${SLICE_NAME}/updateSelectedItem`, async (item, { }) => {
  return item
})

export const updateSelectedItemAsin = createAsyncThunk(`${SLICE_NAME}/updateSelectedItemAsin`, async (item, { }) => {
  return item
})

export const toggleAsinModal = createAsyncThunk(`${SLICE_NAME}/toggleAsinModal`, async (item, { }) => {
  return item
})

export const updateSelectedOrderDetailsRequests = createAsyncThunk(`${SLICE_NAME}/updateSelectedOrderDetailsRequests`, async (allRequests, { }) => {
  return allRequests
})

export const addNewAccessoryToOrder = createAsyncThunk(`${SLICE_NAME}/addNewAccessoryToOrder`, async ({ orderItemId, accessoryItemId }) => {
  const response = await axios.post(`${SERVER_URL}/production-management/order/add-accessory-to-order`, { orderItemId, accessoryItemId })

  return response.data
})

export const removeNewAccessoryFromOrder = createAsyncThunk(`${SLICE_NAME}/removeNewAccessoryFromOrder`, async (orderItemCompositionId) => {
  const response = await axios.delete(`${SERVER_URL}/production-management/order/remove-accessory-from-order/${orderItemCompositionId}`)

  return { orderItemCompositionId, data: response.data }
})

export const toggleAddToStackModal = createAsyncThunk(`${SLICE_NAME}/toggleAddToStackModal`, async (item, { }) => {
  return item
})

export const toggleHoldOrderModal = createAsyncThunk(`${SLICE_NAME}/toggleHoldOrderModal`, async (item, { }) => {
  return item
})

export const addToStack = createAsyncThunk(`${SLICE_NAME}/addToStack`, async ({ item, stackId }, { dispatch, getState }) => {
  await axios.patch(`${SERVER_URL}/production-management/order/add-to-stack/${item.id}`, { stackId })
  if (getState()[SLICE_NAME].replaceOrderDetails && getState()[SLICE_NAME].replaceOrderDetails.firstOrder) {
    await dispatch(getReplaceOrders(getState()[SLICE_NAME].replaceOrderDetails.firstOrder.orderNumber))
  }

  await dispatch(getItems({ type: getState()[SLICE_NAME].listType }))
  return stackId
})

export const getReviewRequests = createAsyncThunk(`${SLICE_NAME}/getReviewRequests`, async ({ role }, { }) => {
  const request = await axios.get(`${SERVER_URL}/production-management/order/review-requests/?role=${role}`)
  return request.data
})

export const assignStackWork = createAsyncThunk(`${SLICE_NAME}/stack-work/assign`, async ({ role, stackId }, { dispatch, getState }) => {
  const request = await axios.post(`${SERVER_URL}/production-management/stack-work/assign?role=${role}`, { stackId })
  return request.data
})

export const holdStackWorkByUser = createAsyncThunk(`${SLICE_NAME}/stack-work/hold-by-user`, async ({ role, stackWorkId, reason }, { dispatch, getState }) => {
  const request = await axios.post(`${SERVER_URL}/production-management/stack-work/hold-by-user?role=${role}`, { stackWorkId, reason })
  return request.data
})

export const unholdStackWorkByUser = createAsyncThunk(`${SLICE_NAME}/stack-work/unhold-by-user`, async ({ role, stackWorkId }, { dispatch, getState }) => {
  const request = await axios.patch(`${SERVER_URL}/production-management/stack-work/unhold-by-user/${stackWorkId}?role=${role}`)
  return request.data
})

export const getLiveTechnicians = createAsyncThunk(`${SLICE_NAME}/getLiveTechnicians`, async () => {
  const request = await axios.get(`${SERVER_URL}/production-management/order/live-technicians`)
  return request.data
})

export const setRowsPerPage = createAsyncThunk(`${SLICE_NAME}/setRowsPerPage`, async (pageSize) => {
  return pageSize
})

export const setPageNo = createAsyncThunk(`${SLICE_NAME}/setPageNo`, async (pageNo) => {
  return pageNo
})

export const resetPagination = createAsyncThunk(`${SLICE_NAME}/resetPagination`, async (item) => {
  return item
})

export const setActiveListFilters = createAsyncThunk(`${SLICE_NAME}/setActiveListFilters`, async (item) => {
  return item
})

export const resetActiveListFilters = createAsyncThunk(`${SLICE_NAME}/resetActiveListFilters`, async (item) => {
  return item
})

export const getReplaceOrders = createAsyncThunk(`${SLICE_NAME}/getReplaceOrders`, async (orderNumber) => {
  const request = await axios.get(`${SERVER_URL}/production-management/order/get-replaceable-orders/${orderNumber}`)

  return request.data
})

export const replaceOrders = createAsyncThunk(`${SLICE_NAME}/replaceOrders`, async ({ firstOrderNumber, secondOrderNumber }, { dispatch, getState }) => {
  const request = await axios.post(`${SERVER_URL}/production-management/order/replace-orders`, {
    firstOrderNumber, secondOrderNumber
  })

  await dispatch(getReplaceOrders(getState()[SLICE_NAME].replaceOrderDetails.firstOrder.orderNumber))

  return request.data
})

export const setOrderDetailsList = createAsyncThunk(`${SLICE_NAME}/setOrderDetailsList`, async ({ orders }) => {
  return orders
})

const defaultInitialState = {
  list: [],
  totalList: 0,
  listRole: null,
  activeListFilters: { search: '', type: undefined, status: undefined, stackName: undefined, receivedDate: undefined },
  selectedItem: null,
  sidebarOpen: false,
  sidebarManageAsinOpen: false,
  sidebarAsinOpen: false,
  selectedItemListing: null,
  asinModalOpen: false,
  orderReports: [],
  addExtendableAccessoryModal: {
    isOpen: false,
    orderItem: null
  },
  addToStackModal: {
    isOpen: false,
    item: null
  },
  holdOrderModal: {
    isOpen: false,
    item: null
  },
  orderDetailsList: [],
  reviewRequests: [],
  submittedOrderItems: [],
  pagination: {
    pageNo: 0,
    pageSize: 25
  },
  replaceOrderList: [],
  replaceOrderListLoading: false,
}

export const Slice = createSlice({
  name: SLICE_NAME,
  initialState: cloneDeep(defaultInitialState),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAccessoryList.fulfilled, (state, action) => {
        state.accessoryList = action.payload
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.categoryList = action.payload
      })
      .addCase(getSubcategories.fulfilled, (state, action) => {
        state.subcategoryList = action.payload
      })
      .addCase(getFields.fulfilled, (state, action) => {
        state.fieldList = action.payload
      })
      .addCase(getItems.fulfilled, (state, action) => {
        state.list = action.payload.list
        state.totalList = action.payload.totalList

        state.orderTypesCount = action.payload.orderTypesCount
        state.orderDatesCount = action.payload.orderDatesCount
        state.orderStackCount = action.payload.orderStackCount
        state.orderStatusCount = action.payload.orderStatusCount
        state.orderUserCount = action.payload.orderUserCount

        // state.activeListFilters = action.payload.activeListFilters

        if (state.selectedItem) {
          state.selectedItem = state.list.find(i => state.selectedItem.id === i.id)
        }
      })
      .addCase(getItem.pending, (state, action) => {
        state.orderDetailsList = []
        state.submittedOrderItems = []
        state.orderDetailsLoading = true
      })
      .addCase(getItem.fulfilled, (state, action) => {
        state.orderDetailsList = action.payload
        state.orderDetailsLoading = false
      })
      .addCase(getItem.rejected, (state, action) => {
        state.orderDetailsList = []
        state.orderDetailsLoading = false
      })
      .addCase(searchItem.pending, (state, action) => {
        state.searchedOrder = null
        state.listingAsinsBySkus = null
        state.searchedOrderLoading = true
      })
      .addCase(searchItem.fulfilled, (state, action) => {
        state.searchedOrder = action.payload.order
        state.listingAsinsBySkus = action.payload.listingAsinsBySkus
        state.searchedOrderLoading = false
      })
      .addCase(searchItem.rejected, (state, action) => {
        state.searchedOrder = null
        state.listingAsinsBySkus = null
        state.searchedOrderLoading = false
      })
      .addCase(toggleSidebar.fulfilled, (state, action) => {
        if (state.sidebarOpen) {
          state.inventoryItems = null
          state.selectedInventoryItem = null
          state.inventoryOrderList = null
          state.inventoryOrderLoading = false
        }

        state.sidebarOpen = !state.sidebarOpen
        state.selectedItem = action.payload ? action.payload : cloneDeep(defaultInitialState.selectedItem)

        if (state.selectedItem) {
          state.selectedInventoryItem = {
            Image: state.selectedItem.productDetails[0].imageUrl,
            SKU: state.selectedItem.productDetails[0].sku,
            Title: state.selectedItem.productDetails[0].title,
            StockLevel: state.selectedItem.productDetails[0].stockLevel,
            StockValue: state.selectedItem.productDetails[0].stockValue
          }
        }
      })
      .addCase(toggleAddExtendableAccessoryModal.fulfilled, (state, action) => {
        state.addExtendableAccessoryModal = {
          isOpen: !state.addExtendableAccessoryModal.isOpen,
          orderItem: action.payload
        }
      })
      .addCase(uploadImage.fulfilled, (state, action) => {
        const productImages = state.selectedItem.productImages || []

        state.selectedItem = { ...state.selectedItem, productImages: [...productImages, action.payload] }
      })
      .addCase(removeImage.fulfilled, (state, action) => {
        let productImages = state.selectedItem.productImages || []
        const deletedProductImage = state.selectedItem.productImages.find(p => p.id === action.payload)
        deletedProductImage._delete = true

        productImages = state.selectedItem.productImages.filter(p => p.id !== action.payload)

        state.selectedItem = { ...state.selectedItem, productImages: [...productImages, { ...deletedProductImage }] }
      })
      .addCase(updateSelectedItem.fulfilled, (state, action) => {
        state.selectedItem = { ...action.payload }
      })
      .addCase(setAddItemsLoading.fulfilled, (state, action) => {
        state.addItemsLoading = action.payload
      })
      .addCase(addItems.fulfilled, (state) => {
        state.inventoryItems = null
        state.selectedInventoryItem = null
        state.inventoryOrderList = null
        state.inventoryOrderLoading = false

        state.selectedItem = cloneDeep(defaultInitialState.selectedItem)

        state.addItemsLoading = false

        notifySuccess({ message: 'Order(s) has been added successfully.' })
      })
      .addCase(addItems.rejected, (state) => {
        state.addItemsLoading = false
      })
      .addCase(saveItem.pending, (state) => {
        state.addOrderWorkLoading = true
      })
      .addCase(saveItem.fulfilled, (state, action) => {
        state.addOrderWorkLoading = false
        state.submittedOrderItems = [...state.submittedOrderItems, action.payload.orderItemId]

        state.orderDetailsList = state.orderDetailsList.map(od => {
          if (od.stack && action.payload.order.stack && od.stack.id === action.payload.order.stack.id) {
            return {
              ...od,
              stack: {
                ...action.payload.order.stack
              }
            }
          }

          return od
        })

        notifySuccess({ message: 'Your work has been submitted successfully.' })
      })

      .addCase(saveItem.rejected, (state) => {
        state.addOrderWorkLoading = false
      })

      .addCase(saveItemAsDraft.pending, (state) => {
        state.draftOrderWorkLoading = true
      })

      .addCase(saveItemAsDraft.fulfilled, (state, action) => {
        state.draftOrderWorkLoading = false

        state.orderDetailsList = state.orderDetailsList.map(od => {
          if (od.stack && action.payload.order.stack && od.stack.id === action.payload.order.stack.id) {
            return {
              ...od,
              stack: {
                ...action.payload.order.stack
              }
            }
          }

          return od
        })

        notifySuccess({ message: 'Your work has been drafted successfully.' })
      })

      .addCase(saveItemAsDraft.rejected, (state) => {
        state.draftOrderWorkLoading = false
      })

      .addCase(addNote.fulfilled, (state, action) => {
        // state.selectedItem = { ...state.selectedItem };
        // state.pendingNotes.push(action.payload)
      })
      .addCase(getOpenOrdersLW.pending, (state, action) => {
        state.inventoryOrderList = null
        state.inventoryOrderLoading = true
      })
      .addCase(getOpenOrdersLW.fulfilled, (state, action) => {
        state.inventoryOrderList = action.payload
        state.inventoryOrderLoading = false
      })
      .addCase(selectInventoryItem.fulfilled, (state, action) => {
        state.selectedInventoryItem = action.payload
      })
      .addCase(updateSelectedOrderDetailsRequests.fulfilled, (state, action) => {
        if (state.orderDetailsList) {
          state.orderDetailsList = state.orderDetailsList.map(od => ({ ...od, orderRequests: action.payload.filter(o => o.orderId === od.id) }))
        }
      })
      .addCase(addNewAccessoryToOrder.fulfilled, (state, action) => {
        if (state.orderDetailsList) {
          state.orderDetailsList = state.orderDetailsList.map(od => ({
            ...od,
            orderItems: od.orderItems.map(orderItem => {
              if (orderItem.id === action.payload.orderItemId) {
                return {
                  ...orderItem,
                  orderItemCompositions: orderItem.orderItemCompositions.find(oic => oic.id === action.payload.id) ? orderItem.orderItemCompositions.map(oic => {
                    if (oic.id === action.payload.id) {
                      return action.payload
                    }
                    return oic
                  }) : [...orderItem.orderItemCompositions, action.payload]
                }
              }
              return orderItem
            })
          }))
        }
      })
      .addCase(removeNewAccessoryFromOrder.fulfilled, (state, action) => {
        if (state.orderDetailsList) {
          state.orderDetailsList = state.orderDetailsList.map(od => ({
            ...od,
            orderItems: od.orderItems.map(orderItem => {
              return {
                ...orderItem,
                orderItemCompositions: action.payload?.data?.id ? orderItem.orderItemCompositions.map(oic => {
                  if (oic.id === action.payload.data.id) {
                    return action.payload.data
                  }
                  return oic
                }) : orderItem.orderItemCompositions.filter(orderItemComposition => orderItemComposition.id !== action.payload.orderItemCompositionId)
              }
            })
          }))
        }
      })
      .addCase(toggleAddToStackModal.fulfilled, (state, action) => {
        state.addToStackModal = {
          isOpen: !state.addToStackModal.isOpen,
          item: action.payload
        }
      })
      .addCase(addToStack.fulfilled, (state, action) => {
        state.addToStackModal = {
          isOpen: !state.addToStackModal.isOpen,
          item: null
        }
      })
      .addCase(toggleHoldOrderModal.fulfilled, (state, action) => {
        state.holdOrderModal = {
          isOpen: !state.holdOrderModal.isOpen,
          item: action.payload
        }
      })
      .addCase(holdItem.fulfilled, (state) => {
        state.holdOrderModal = {
          isOpen: false,
          item: null
        }
      })
      .addCase(getReviewRequests.fulfilled, (state, action) => {
        state.reviewRequests = action.payload
      })
      .addCase(assignStackWork.fulfilled, (state, action) => {
        state.orderDetailsList = state.orderDetailsList.map(od => {
          if (od.stack && od.stack.id === action.payload.stackId) {
            return {
              ...od,
              stack: {
                ...od.stack,
                stackWorks: [...od.stack.stackWorks, action.payload]
              }
            }
          }

          return od
        })
      })
      .addCase(holdStackWorkByUser.fulfilled, (state, action) => {
        state.orderDetailsList = state.orderDetailsList.map(od => ({
          ...od,
          stack: {
            ...od.stack,
            stackWorks: od.stack.stackWorks.map(sw => {
              if (sw.id === action.payload.stackWorkId) {
                return { ...sw, status: PM_STACK_WORK_STATUS.HOLD, stackWorkHoldEvents: [...sw.stackWorkHoldEvents, action.payload] }
              }
              return sw
            })
          }
        }))
      })
      .addCase(unholdStackWorkByUser.fulfilled, (state, action) => {
        state.orderDetailsList = state.orderDetailsList.map(od => ({
          ...od,
          stack: {
            ...od.stack,
            stackWorks: od.stack.stackWorks.map(sw => {
              if (sw.id === action.payload.stackWorkId) {
                return {
                  ...sw,
                  status: PM_STACK_WORK_STATUS.IN_PROGRESS,
                  stackWorkHoldEvents: sw.stackWorkHoldEvents.map(swe => {
                    if (swe.id === action.payload.id) {
                      return { ...action.payload }
                    }

                    return swe
                  })
                }
              }

              return sw
            })
          }
        }))
      })
      .addCase(getLiveTechnicians.fulfilled, (state, action) => {
        state.liveTechnicians = action.payload
      })
      .addCase(getGetOrderItemComposition.fulfilled, (state, action) => {
        state.orderDetailsList = state.orderDetailsList.map(od => {
          if (od.lwOrderId === action.payload.OrderId) {
            return {
              ...od,
              orderItems: od.orderItems.map(orderItem => {
                if (orderItem.lwStockItemId === action.payload.StockItemId) {
                  return {
                    ...orderItem,
                    _lwOrderItemComposition: action.payload
                  }
                }
                return orderItem
              })
            }
          }
          return od
        })
      })
      .addCase(setRowsPerPage.fulfilled, (state, action) => {
        state.pagination = {
          ...state.pagination,
          pageSize: action.payload,
          pageNo: 0
        }
      })
      .addCase(setPageNo.fulfilled, (state, action) => {
        state.pagination = {
          ...state.pagination,
          pageNo: action.payload
        }
      })
      .addCase(resetPagination.fulfilled, (state) => {
        state.pagination = {
          ...defaultInitialState.pagination
        }
      })
      .addCase(setActiveListFilters.fulfilled, (state, action) => {
        state.activeListFilters = {
          ...state.activeListFilters,
          [action.payload.name]: action.payload.value
        }
        state.pagination = {
          ...state.pagination,
          pageNo: 0
        }
      })
      .addCase(resetActiveListFilters.fulfilled, (state) => {
        state.activeListFilters = {
          ...defaultInitialState.activeListFilters
        }
      })
      .addCase(getReplaceOrders.pending, (state, action) => {
        state.replaceOrderListLoading = true
      })
      .addCase(getReplaceOrders.fulfilled, (state, action) => {
        state.replaceOrderDetails = action.payload
        state.replaceOrderListLoading = false
      })
      .addCase(getReplaceOrders.rejected, (state, action) => {
        state.replaceOrderDetails = undefined
        state.replaceOrderListLoading = false
      })
      .addCase(replaceOrders.fulfilled, () => {
        notifySuccess({ message: 'Orders replaced successfully.' })
      })
      .addCase(setOrderDetailsList.fulfilled, (state, action) => {
        state.orderDetailsList = action.payload
      })
  }
})

export default Slice.reducer
