// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

import { SERVER_URL } from '@constants'

import { cloneDeep } from 'lodash'

const SLICE_NAME = 'PLSubcategory'
const ENTITY_NAME = 'sub-category'

export const getItems = createAsyncThunk(`${SLICE_NAME}/getItems`, async (parentId, { dispatch, getState }) => {
  parentId = parentId || getState()[SLICE_NAME].parentId
  const response = await axios.get(`${SERVER_URL}/parts-listing/${ENTITY_NAME}`, { params: { categoryId: parentId } })
  return { list: response.data, parentId }
})

export const addItem = createAsyncThunk(`${SLICE_NAME}/addItem`, async (item, { dispatch }) => {
  await axios.post(`${SERVER_URL}/parts-listing/${ENTITY_NAME}`, item)
  await dispatch(getItems())
  return item
})

export const updateItem = createAsyncThunk(`${SLICE_NAME}/updateItem`, async (item, { dispatch }) => {
  await axios.patch(`${SERVER_URL}/parts-listing/${ENTITY_NAME}/${item.id}`, item)
  await dispatch(getItems())
  return item
})

export const copyItem = createAsyncThunk(`${SLICE_NAME}/copyItem`, async (item, { dispatch }) => {
  await axios.post(`${SERVER_URL}/parts-listing/${ENTITY_NAME}/copy`, item)
  await dispatch(getItems())
  return item
})

export const deleteItem = createAsyncThunk(`${SLICE_NAME}/deleteItem`, async (id, { dispatch }) => {
  await axios.delete(`${SERVER_URL}/parts-listing/${ENTITY_NAME}/${id}`)
  await dispatch(getItems())
  return id
})

export const toggleSidebar = createAsyncThunk(`${SLICE_NAME}/toggleSidebar`, async (item, { }) => {
  return item
})

const defaultInitialState = {
  list: [],
  selectedItem: {
    name: '',
    order: '1'
  },
  sidebarOpen: false
}

export const Slice = createSlice({
  name: SLICE_NAME,
  initialState: cloneDeep(defaultInitialState),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getItems.fulfilled, (state, action) => {
        state.list = action.payload.list
        state.parentId = action.payload.parentId
      })
      .addCase(toggleSidebar.fulfilled, (state, action) => {
        state.sidebarOpen = !state.sidebarOpen
        state.selectedItem = action.payload ? action.payload : cloneDeep(defaultInitialState.selectedItem)
      })
      .addCase(addItem.fulfilled, (state) => {
        state.sidebarOpen = false
      })
      .addCase(updateItem.fulfilled, (state) => {
        state.sidebarOpen = false
      })
  }
})

export default Slice.reducer
