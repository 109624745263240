import Avatar from '@components/avatar'
import { Fragment } from 'react'
import { Check } from 'react-feather'

const Toast = ({ type, title, message, time }) => (
    <Fragment>
        <div className='toastify-header'>
            <div className='title-wrapper'>
                <Avatar size='sm' color={type} icon={<Check size={12} />} />
                <h6 className='toast-title'>{title}</h6>
            </div>
            {time && <small className='text-muted'>{time} Min Ago</small>}
        </div>
        <div className='toastify-body'>
            <span role='img' aria-label='toast-text'>
                {message}
            </span>
        </div>
    </Fragment>
)

export default Toast