// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

import { SERVER_URL } from '@constants'

import { cloneDeep } from 'lodash'

const SLICE_NAME = 'User'
const ENTITY_NAME = 'user'

export const getRoles = createAsyncThunk(`${SLICE_NAME}/getRoles`, async () => {
  const response = await axios.get(`${SERVER_URL}/global/role`)
  return response.data
})

export const getItems = createAsyncThunk(`${SLICE_NAME}/getItems`, async ({ filters }) => {
  const response = await axios.get(`${SERVER_URL}/global/${ENTITY_NAME}`, { params: { ...filters } })
  return response.data
})

export const addItem = createAsyncThunk(`${SLICE_NAME}/addItem`, async (item, { dispatch, getState }) => {
  await axios.post(`${SERVER_URL}/global/${ENTITY_NAME}`, item)
  await dispatch(getItems({ filters: getState()[SLICE_NAME].filters }))
  return item
})

export const updateItem = createAsyncThunk(`${SLICE_NAME}/updateItem`, async (item, { dispatch, getState }) => {
  await axios.patch(`${SERVER_URL}/global/${ENTITY_NAME}/${item.id}`, item)
  await dispatch(getItems({ filters: getState()[SLICE_NAME].filters }))
  return item
})

// TODO: Currently not in use, might remove in future
// export const changeActive = createAsyncThunk(`${SLICE_NAME}/changeActive`, async (item, { dispatch, getState }) => {
//   await axios.patch(`${SERVER_URL}/global/${ENTITY_NAME}/change-active/${item.id}`, item)
//   await dispatch(getItems({ filters: getState()[SLICE_NAME].filters }))
//   return item
// })

export const copyItem = createAsyncThunk(`${SLICE_NAME}/copyItem`, async (item, { dispatch, getState }) => {
  await axios.post(`${SERVER_URL}/global/${ENTITY_NAME}`, item)
  await dispatch(getItems({ filters: getState()[SLICE_NAME].filters }))
  return item
})

export const deleteItem = createAsyncThunk(`${SLICE_NAME}/deleteItem`, async (id, { dispatch, getState }) => {
  await axios.delete(`${SERVER_URL}/global/${ENTITY_NAME}/${id}`)
  await dispatch(getItems({ filters: getState()[SLICE_NAME].filters }))
  return id
})

export const toggleSidebar = createAsyncThunk(`${SLICE_NAME}/toggleSidebar`, async (item, { }) => {
  return item
})

export const uploadImage = createAsyncThunk(`${SLICE_NAME}/uploadImage`, async ({ id, image }, { dispatch, getState }) => {
  const formData = new FormData()
  formData.append('image', image)

  const response = await axios.patch(`${SERVER_URL}/global/${ENTITY_NAME}/upload-image/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  await dispatch(getItems({ filters: getState()[SLICE_NAME].filters }))

  const userData = JSON.parse(localStorage.getItem('userData'))
  userData.image = response.data.image
  localStorage.setItem('userData', JSON.stringify(userData))

  return response.data
})

export const setListFilters = createAsyncThunk(`${SLICE_NAME}/setListFilters`, async (item) => {
  return item
})

const defaultInitialState = {
  list: [],
  selectedItem: {
    name: '',
    userRoles: []
  },
  sidebarOpen: false,
  filters: { active: true }
}

export const Slice = createSlice({
  name: SLICE_NAME,
  initialState: cloneDeep(defaultInitialState),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getRoles.fulfilled, (state, action) => {
        state.roleList = action.payload
      })
      .addCase(getItems.fulfilled, (state, action) => {
        state.list = action.payload
      })
      .addCase(toggleSidebar.fulfilled, (state, action) => {
        state.sidebarOpen = !state.sidebarOpen
        state.selectedItem = action.payload ? action.payload : cloneDeep(defaultInitialState.selectedItem)
      })
      .addCase(addItem.fulfilled, (state) => {
        state.sidebarOpen = false
      })
      .addCase(updateItem.fulfilled, (state) => {
        state.sidebarOpen = false
      })
      .addCase(uploadImage.fulfilled, (state, { payload }) => {
        state.selectedItem = { ...state.selectedItem, image: payload.image }
      })
      .addCase(setListFilters.fulfilled, (state, { payload }) => {
        state.filters = { ...state.filters, [payload.name]: payload.value }
      })
  }
})

export default Slice.reducer
