// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'


// ** Axios Imports
import axios from 'axios'

import { SERVER_URL } from '@constants'

import { cloneDeep } from 'lodash'
import { PL_PRODUCT_STATUS, PL_USER_ROLE } from '../../../../utility/Constants'
import { getCategoriesRequest } from '@utils'

const SLICE_NAME = 'PLProduct'
const ENTITY_NAME = 'product'

export const getLWCategories = createAsyncThunk(`${SLICE_NAME}/getLWCategories`, async () => {
  const response = await axios.post(`${SERVER_URL}/linnworks/auth?q=GetCategories`, getCategoriesRequest())
  return response.data
})

export const getCategories = createAsyncThunk(`${SLICE_NAME}/getCategories`, async () => {
  const response = await axios.get(`${SERVER_URL}/parts-listing/category`)
  return response.data
})

export const getSubcategories = createAsyncThunk(`${SLICE_NAME}/getSubcategories`, async (categoryId) => {
  const response = await axios.get(`${SERVER_URL}/parts-listing/sub-category`, { params: { categoryId } })
  return response.data
})

export const getFields = createAsyncThunk(`${SLICE_NAME}/getFields`, async (subcategoryId) => {
  const response = await axios.get(`${SERVER_URL}/parts-listing/field`, { params: { subcategoryId } })
  return response.data
})

export const getItems = createAsyncThunk(`${SLICE_NAME}/getItems`, async (role) => {
  const response = await axios.get(`${SERVER_URL}/parts-listing/${ENTITY_NAME}?role=${role}`)
  return { list: response.data, role }
})

export const getItem = createAsyncThunk(`${SLICE_NAME}/getItem`, async ({ id, role }) => {
  const response = await axios.get(`${SERVER_URL}/parts-listing/${ENTITY_NAME}/${id}?role=${role}`)
  return response.data
})

export const addItem = createAsyncThunk(`${SLICE_NAME}/addItem`, async (role, { dispatch, getState }) => {
  const selectedItem = { ...getState()[SLICE_NAME].selectedItem }

  console.log('addItem selectedItem', selectedItem)

  selectedItem.productNotes = selectedItem.productNotes || []
  selectedItem.productImages = selectedItem.productImages || []

  await axios.post(`${SERVER_URL}/parts-listing/${ENTITY_NAME}?role=${role}`, selectedItem)
  await dispatch(getItems(role))
  return selectedItem
})

export const assignItem = createAsyncThunk(`${SLICE_NAME}/assignItem`, async ({ id, role }, { dispatch, getState }) => {
  await axios.patch(`${SERVER_URL}/parts-listing/${ENTITY_NAME}/assign/${id}?role=${role}`)
  await dispatch(getItems(getState()[SLICE_NAME].listRole))
  return id
})

export const submitItem = createAsyncThunk(`${SLICE_NAME}/submitItem`, async ({ id, role }, { dispatch, getState }) => {
  let body = {};

  const selectedItem = { ...getState()[SLICE_NAME].selectedItem }

  if (role === PL_USER_ROLE.PRODUCT_REVIEWER && selectedItem.status === PL_PRODUCT_STATUS.UNDER_REVIEW_L1) {

    body = {
      id: selectedItem.productDetails.id,
      whatAreYouListingApproved: selectedItem.productDetails.whatAreYouListingApproved,
      whatAreYouListingRejectionReason: selectedItem.productDetails.whatAreYouListingRejectionReason,
      picturesApproved: selectedItem.productDetails.picturesApproved,
      picturesRejectionReason: selectedItem.productDetails.picturesRejectionReason,
      postagePackingApproved: selectedItem.productDetails.postagePackingApproved,
      postagePackingRejectionReason: selectedItem.productDetails.postagePackingRejectionReason,
    }
  } else if (role === PL_USER_ROLE.PRODUCT_REVIEWER && selectedItem.status === PL_PRODUCT_STATUS.UNDER_REVIEW_L2) {
    body = {
      id: selectedItem.productListings.id,
      attributesDescriptionApproved: selectedItem.productListings.attributesDescriptionApproved,
      attributesDescriptionRejectionReason: selectedItem.productListings.attributesDescriptionRejectionReason,
      priceApproved: selectedItem.productListings.priceApproved,
      priceRejectionReason: selectedItem.productListings.priceRejectionReason,
      productListingAsins: selectedItem.productListings.productListingAsins.map(a => ({ id: a.id, approved: a.approved, rejectionReason: a.rejectionReason })),
    }
  }

  await axios.patch(`${SERVER_URL}/parts-listing/${ENTITY_NAME}/submit/${id}?role=${role}`, body)
  await dispatch(getItems(role))
  return id
})

export const deleteItem = createAsyncThunk(`${SLICE_NAME}/deleteItem`, async ({ id, role }, { dispatch, getState }) => {
  await axios.delete(`${SERVER_URL}/parts-listing/${ENTITY_NAME}/${id}?role=${role}`)
  await dispatch(getItems(getState()[SLICE_NAME].listRole))
  return id
})

export const uploadImage = createAsyncThunk(`${SLICE_NAME}/uploadImage`, async ({ id, image, role }, { dispatch, getState }) => {
  const formData = new FormData()
  formData.append('image', image)

  const response = await axios.post(`${SERVER_URL}/parts-listing/${ENTITY_NAME}/upload-image/${id}?role=${role}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

  return response.data
})

export const removeImage = createAsyncThunk(`${SLICE_NAME}/removeImage`, async (id, { dispatch, getState }) => {
  return id
})

export const toggleSidebar = createAsyncThunk(`${SLICE_NAME}/toggleSidebar`, async (item, { }) => {
  return item
})

export const updateSelectedItem = createAsyncThunk(`${SLICE_NAME}/updateSelectedItem`, async (item, { }) => {
  return item
})

export const updateSelectedItemAsin = createAsyncThunk(`${SLICE_NAME}/updateSelectedItemAsin`, async (item, { }) => {
  return item
})

export const toggleAsinModal = createAsyncThunk(`${SLICE_NAME}/toggleAsinModal`, async (item, { }) => {
  return item
})

// export const updateAndSubmitSelectedItem = createAsyncThunk(`${SLICE_NAME}/updateAndSubmitSelectedItem`, async (role, item, { dispatch, getState }) => {
//   await dispatch(updateSelectedItem(item))

//   console.log('updateAndSubmitSelectedItem', getState()[SLICE_NAME].selectedItem)

//   await dispatch(addItem(role, getState()[SLICE_NAME].selectedItem))
//   return getState()[SLICE_NAME].selectedItem
// })

const defaultInitialState = {
  list: [],
  listRole: null,
  selectedItem: null,
  sidebarOpen: false,
  selectedItemAsin: null,
  asinModalOpen: false,
}

export const Slice = createSlice({
  name: SLICE_NAME,
  initialState: cloneDeep(defaultInitialState),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getCategories.fulfilled, (state, action) => {
        state.categoryList = action.payload
      })
      .addCase(getSubcategories.pending, (state, action) => {
        state.subcategoryList = []
      })
      .addCase(getSubcategories.fulfilled, (state, action) => {
        state.subcategoryList = action.payload
      })
      .addCase(getFields.fulfilled, (state, action) => {
        state.fieldList = action.payload
      })
      .addCase(getItems.fulfilled, (state, action) => {
        state.list = action.payload.list
        state.listRole = action.payload.role
      })
      .addCase(getLWCategories.fulfilled, (state, action) => {
        state.lwCategoryList = action.payload
      })
      .addCase(getItem.fulfilled, (state, action) => {
        state.selectedItem = { ...action.payload, productDetails: { ...action.payload.productDetails[0] }, productListings: action.payload.productListings.length ? { ...action.payload.productListings[0] } : null }
      })
      .addCase(toggleSidebar.fulfilled, (state, action) => {
        state.sidebarOpen = !state.sidebarOpen
        state.selectedItem = action.payload ? action.payload : cloneDeep(defaultInitialState.selectedItem)
      })
      .addCase(toggleAsinModal.fulfilled, (state, action) => {
        state.asinModalOpen = !state.asinModalOpen
        state.selectedItemAsin = action.payload ? action.payload : cloneDeep(defaultInitialState.selectedItemAsin)
      })
      .addCase(updateSelectedItemAsin.fulfilled, (state, action) => {
        let productListingAsins = state.selectedItem.productListings.productListingAsins || []

        if (action.payload.id) {
          productListingAsins = state.selectedItem.productListings.productListingAsins.filter(p => p.id !== action.payload.id)
        }

        state.selectedItem = {
          ...state.selectedItem,
          productListings: { ...state.selectedItem.productListings, productListingAsins: [...productListingAsins, action.payload] }
        };

        state.selectedItemAsin = null

        state.asinModalOpen = false
      })
      .addCase(uploadImage.fulfilled, (state, action) => {
        let productImages = state.selectedItem.productImages || []

        state.selectedItem = { ...state.selectedItem, productImages: [...productImages, action.payload] }
      })
      .addCase(removeImage.fulfilled, (state, action) => {
        let productImages = state.selectedItem.productImages || []
        const deletedProductImage = state.selectedItem.productImages.find(p => p.id === action.payload)
        deletedProductImage._delete = true

        productImages = state.selectedItem.productImages.filter(p => p.id !== action.payload)

        state.selectedItem = { ...state.selectedItem, productImages: [...productImages, { ...deletedProductImage }] }
      })
      .addCase(updateSelectedItem.fulfilled, (state, action) => {
        state.selectedItem = { ...action.payload }
      })
      .addCase(addItem.fulfilled, (state) => {
        state.sidebarOpen = false
      })
  }
})

export default Slice.reducer
