// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

import { SERVER_URL } from '@constants'
import { notifyError } from '@utils'

import { cloneDeep } from 'lodash'

const SLICE_NAME = 'Reserve'
const ENTITY_NAME = 'reserve'

export const getCategories = createAsyncThunk(`${SLICE_NAME}/getCategories`, async () => {
  const response = await axios.get(`${SERVER_URL}/parts-listing/category`)
  return response.data
})

export const getSubcategories = createAsyncThunk(`${SLICE_NAME}/getSubcategories`, async (categoryId) => {
  const response = await axios.get(`${SERVER_URL}/parts-listing/sub-category`, { params: { categoryId } })
  return response.data
})

export const getItems = createAsyncThunk(`${SLICE_NAME}/getItems`, async ({ filters }, {getState}) => {
  const response = await axios.get(`${SERVER_URL}/reserves/${ENTITY_NAME}`, { params: { ...(filters ? filters : getState()[SLICE_NAME].filters) } })
  return response.data
})

export const addItem = createAsyncThunk(`${SLICE_NAME}/addItem`, async (item, { dispatch }) => {
  await axios.post(`${SERVER_URL}/reserves/${ENTITY_NAME}`, item)
  await dispatch(getItems({}))
  return item
})

export const updateItem = createAsyncThunk(`${SLICE_NAME}/updateItem`, async (item, { dispatch }) => {
  await axios.patch(`${SERVER_URL}/reserves/${ENTITY_NAME}/${item.id}`, item)
  await dispatch(getItems({}))
  return item
})

export const copyItem = createAsyncThunk(`${SLICE_NAME}/copyItem`, async (item, { dispatch }) => {
  await axios.post(`${SERVER_URL}/reserves/${ENTITY_NAME}`, item)
  await dispatch(getItems({}))
  return item
})

export const deleteItem = createAsyncThunk(`${SLICE_NAME}/deleteItem`, async (id, { dispatch }) => {
  await axios.delete(`${SERVER_URL}/reserves/${ENTITY_NAME}/${id}`)
  await dispatch(getItems({}))
  return id
})

export const sync = createAsyncThunk(`${SLICE_NAME}/sync`, async (item, { dispatch }) => {
  await axios.post(`${SERVER_URL}/reserves/${ENTITY_NAME}/sync`, {})
  await dispatch(getItems({}))
  return item
})

export const toggleSidebar = createAsyncThunk(`${SLICE_NAME}/toggleSidebar`, async (item, { }) => {
  return item
})

export const setListFilters = createAsyncThunk(`${SLICE_NAME}/setListFilters`, async (item, { dispatch, getState }) => {
  dispatch(getItems({ filters: { ...getState()[SLICE_NAME].filters, [item.name]: item.value } }))
  return item
})

export const resetListFilters = createAsyncThunk(`${SLICE_NAME}/resetListFilters`, async (item) => {
  return item
})

export const clearSubcategories = createAsyncThunk(`${SLICE_NAME}/clearSubcategories`, async (item) => {
  return item
})

export const changeOrder = createAsyncThunk(`${SLICE_NAME}/changeOrder`, async ({ id, direction }, { dispatch, getState }) => {
  const filters = getState()[SLICE_NAME].filters
  if (filters.categoryId || filters.subcategoryId) {
    notifyError({ message: 'Change order will only work if filters are Not selected' })
  } else {
    const list = getState()[SLICE_NAME].list.map(i => ({ id: i.id, order: i.order }))

    const itemIndex = list.findIndex(i => i.id === id)

    if (itemIndex > -1) {
      const newItemIndex = direction === -1 ? itemIndex - 1 : itemIndex + 1
      const item = list[itemIndex]

      if (newItemIndex > -1 && newItemIndex < list.length) {
        list.splice(itemIndex, 1)
        list.splice(newItemIndex, 0, item)

        list.forEach((item, index) => {
          item.order = index
        })

        await axios.put(`${SERVER_URL}/reserves/${ENTITY_NAME}/change-order`, list)
        await dispatch(getItems({}))
      }
    }
  }

  return item
})

export const getLowStockReserves = createAsyncThunk(`${SLICE_NAME}/getLowStockReserves`, async ({ status }) => {
  const response = await axios.get(`${SERVER_URL}/reserves/${ENTITY_NAME}`, { params: { status } })
  return response.data
})

const defaultInitialState = {
  list: [],
  selectedItem: {
    name: '',
    order: '1',
    isActive: true
  },
  sidebarOpen: false,
  filters: {
    categoryId: null,
    subcategoryId: null,
    status: null
  },
  lowStockReserves: []
}

export const Slice = createSlice({
  name: SLICE_NAME,
  initialState: cloneDeep(defaultInitialState),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getCategories.fulfilled, (state, action) => {
        state.categoryList = action.payload
      })
      .addCase(getSubcategories.fulfilled, (state, action) => {
        state.subcategoryList = action.payload
      })
      .addCase(getItems.fulfilled, (state, action) => {
        state.list = action.payload
      })
      .addCase(sync.pending, (state, action) => {
        state.syncLoading = true
      })
      .addCase(sync.fulfilled, (state, action) => {
        state.syncLoading = false
      })
      .addCase(toggleSidebar.fulfilled, (state, action) => {
        state.sidebarOpen = !state.sidebarOpen
        state.selectedItem = action.payload ? action.payload : cloneDeep(defaultInitialState.selectedItem)
      })
      .addCase(addItem.pending, (state) => {
        state.saveItemLoading = true
      })
      .addCase(addItem.fulfilled, (state) => {
        state.sidebarOpen = false
        state.saveItemLoading = false
      })
      .addCase(updateItem.pending, (state) => {
        state.saveItemLoading = true
      })
      .addCase(updateItem.fulfilled, (state) => {
        state.sidebarOpen = false
        state.saveItemLoading = false
      })
      .addCase(setListFilters.fulfilled, (state, action) => {
        state.filters = {
          ...state.filters,
          [action.payload.name]: action.payload.value
        }
      })
      .addCase(resetListFilters.fulfilled, (state, action) => {
        state.filters = {
          ...defaultInitialState.filters
        }
      })
      .addCase(clearSubcategories.fulfilled, (state) => {
        state.subcategoryList = null
        state.filters = {
          ...state.filters,
          subcategoryId: null
        }
      })
      .addCase(getLowStockReserves.fulfilled, (state, action) => {
        state.lowStockReserves = action.payload
      })
  }
})

export default Slice.reducer
