// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import users from '@src/views/apps/user/store'
import email from '@src/views/apps/email/store'
import invoice from '@src/views/apps/invoice/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'
import Shared from '@src/views/shared/store'
import Setting from '@src/views/global/setting/store'
import SettingValue from '@src/views/global/setting-value/store'
import User from '@src/views/global/user/store'
import Role from '@src/views/global/role/store'
import Contact from '@src/views/global/contact/store'
import CLAccessoryGroup from '@src/views/computer-listing/accessory-group/store'
import CLAccessory from '@src/views/computer-listing/accessory/store'
import CLAccessoryItem from '@src/views/computer-listing/accessory-item/store'
import CLProduct from '@src/views/computer-listing/product/store'
import PMOrder from '@src/views/production-management/order/store'
import PMStack from '@src/views/production-management/stack/store'
import PMOrderRequest from '@src/views/production-management/order-request/store'
import PLProduct from '@src/views/parts-listing/product/store'
import PLProductReprice from '@src/views/parts-listing/product-reprice/store'
import PLCategory from '@src/views/parts-listing/category/store'
import PLSubcategory from '@src/views/parts-listing/subcategory/store'
import PLField from '@src/views/parts-listing/field/store'
import PLFieldValue from '@src/views/parts-listing/field-value/store'
import Reserve from '@src/views/reserve/reserve/store'
import ReserveItem from '@src/views/reserve/reserve-item/store'
import Report from '@src/views/global/reports/store'

const rootReducer = {
  auth,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  permissions,
  Shared,
  PLProduct,
  PLProductReprice,
  CLAccessoryGroup,
  CLAccessory,
  CLAccessoryItem,
  CLProduct,
  PMOrder,
  PMOrderRequest,
  PLCategory,
  PLSubcategory,
  PLField,
  PLFieldValue,
  Setting,
  SettingValue,
  User,
  Role,
  Contact,
  Reserve,
  ReserveItem,
  PMStack,
  Report
}

export default rootReducer
