// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'


// ** Axios Imports
import axios from 'axios'

import { SERVER_URL } from '@constants'
import { getInventoryItemsRequest, getStockItemsRequest, sortCompareString } from '@utils'

import { cloneDeep } from 'lodash'

const SLICE_NAME = 'CLProduct'
const ENTITY_NAME = 'product'

export const getLWStockItems = createAsyncThunk(`${SLICE_NAME}/getLWStockItems`, async () => {
  const response = await axios.post(`${SERVER_URL}/linnworks/auth?q=GetStockItems`, getStockItemsRequest())
  return response.data
})

export const getInventoryItemsLW = createAsyncThunk(`${SLICE_NAME}/getInventoryItemsLW`, async ({ searchTerm, exactMatch }) => {
  const response = await axios.post(`${SERVER_URL}/linnworks/auth?q=GetInventoryItems`, getInventoryItemsRequest(searchTerm, exactMatch))
  return response.data
})

export const selectInventoryItem = createAsyncThunk(`${SLICE_NAME}/selectInventoryItem`, async (item) => {
  return item
})

export const getAccessoryList = createAsyncThunk(`${SLICE_NAME}/getAccessoryList`, async () => {
  const response = await axios.get(`${SERVER_URL}/computer-listing/accessory-group?includeChild=true`)
  return response.data
})

export const getCategories = createAsyncThunk(`${SLICE_NAME}/getCategories`, async () => {
  const response = await axios.get(`${SERVER_URL}/parts-listing/category`)
  return response.data
})

export const getSubcategories = createAsyncThunk(`${SLICE_NAME}/getSubcategories`, async (categoryId) => {
  const response = await axios.get(`${SERVER_URL}/parts-listing/sub-category`, { params: { categoryId } })
  return response.data
})

export const getFields = createAsyncThunk(`${SLICE_NAME}/getFields`, async (subcategoryId) => {
  const response = await axios.get(`${SERVER_URL}/computer-listing/field`, { params: { subcategoryId } })
  return response.data
})

export const getItems = createAsyncThunk(`${SLICE_NAME}/getItems`, async ({ role, filters, pagination }, { dispatch, getState }) => {
  const response = await axios.get(`${SERVER_URL}/computer-listing/${ENTITY_NAME}`, { params: { role, ...(filters ? filters : getState()[SLICE_NAME].filters), ...(pagination ? pagination : getState()[SLICE_NAME].pagination) } })
  return { list: response.data.results, role, totalList: response.data.total }
})

export const getItem = createAsyncThunk(`${SLICE_NAME}/getItem`, async (id) => {
  const response = await axios.get(`${SERVER_URL}/computer-listing/${ENTITY_NAME}/${id}`)
  return response.data
})

export const addItem = createAsyncThunk(`${SLICE_NAME}/addItem`, async (role, { dispatch, getState }) => {
  const selectedItem = { ...getState()[SLICE_NAME].selectedItem }

  // selectedItem.productNotes = selectedItem.productNotes || [];
  // selectedItem.productImages = selectedItem.productImages || [];

  if (selectedItem.productListings) {
    try {
      selectedItem.productListings.productListingAttributes = [{
        "fieldId": 1,
        "value": "this is value"
      }];
    } catch (e) { }
  }

  await axios.post(`${SERVER_URL}/computer-listing/${ENTITY_NAME}?role=${role}`, selectedItem)
  await dispatch(getItems({ role }))
  return selectedItem
})

export const submitItem = createAsyncThunk(`${SLICE_NAME}/submitItem`, async ({ id, productListingId, role }, { dispatch, getState }) => {
  let body = {};

  let url = `${SERVER_URL}/computer-listing/${ENTITY_NAME}/submit/${id}?role=${role}`

  if (productListingId) {
    url += `&productListingIds[]=${productListingId}`
  }

  await axios.patch(url, body)
  await dispatch(getItems({ role: getState()[SLICE_NAME].listRole }))
  return id
})

export const saveItem = createAsyncThunk(`${SLICE_NAME}/saveItem`, async ({ role, item }, { dispatch }) => {
  const response = await axios.post(`${SERVER_URL}/computer-listing/${ENTITY_NAME}?role=${role}`, item)
  const product = response.data;
  await dispatch(submitItem({ id: product.id, productListingId: item.productListings ? item.productListings.id : undefined, role }))
  return item
})

export const addNote = createAsyncThunk(`${SLICE_NAME}/addNote`, async ({ note }, { dispatch }) => {
  return note
})

export const addToCatalogItem = createAsyncThunk(`${SLICE_NAME}/assignItem`, async ({ id, role, type }, { dispatch, getState }) => {
  await axios.post(`${SERVER_URL}/computer-listing/${ENTITY_NAME}/add-to-catalog?productId=${id}&role=${role}&type=${type}`)
  await dispatch(getItems({ role: getState()[SLICE_NAME].listRole }))
  return id
})

export const removeFromCatalogItem = createAsyncThunk(`${SLICE_NAME}/assignItem`, async ({ id, role, type }, { dispatch, getState }) => {
  await axios.delete(`${SERVER_URL}/computer-listing/${ENTITY_NAME}/remove-from-catalog/${id}?role=${role}`)
  await dispatch(getItems({ role: getState()[SLICE_NAME].listRole }))
  return id
})

export const assignItem = createAsyncThunk(`${SLICE_NAME}/assignItem`, async ({ id, role }, { dispatch, getState }) => {
  await axios.patch(`${SERVER_URL}/computer-listing/${ENTITY_NAME}/assign/${id}?role=${role}`)
  await dispatch(getItems({ role: getState()[SLICE_NAME].listRole }))
  return id
})

export const deleteItem = createAsyncThunk(`${SLICE_NAME}/deleteItem`, async ({ id, role }, { dispatch, getState }) => {
  await axios.delete(`${SERVER_URL}/computer-listing/${ENTITY_NAME}/${id}?role=${role}`)
  await dispatch(getItems({ role: getState()[SLICE_NAME].listRole }))
  return id
})

export const updateProductListingAsins = createAsyncThunk(`${SLICE_NAME}/updateProductListingAsins`, async ({ productListingAsins, role }, { dispatch, getState }) => {
  await axios.post(`${SERVER_URL}/computer-listing/${ENTITY_NAME}/update-product-listing-asins?role=${role}`, { productListingAsins })
  await dispatch(getItems({ role: getState()[SLICE_NAME].listRole }))
  return id
})

export const syncItem = createAsyncThunk(`${SLICE_NAME}/syncItem`, async ({ productDetailId, role }, { dispatch, getState }) => {
  await axios.post(`${SERVER_URL}/computer-listing/${ENTITY_NAME}/sync?productDetailId=${productDetailId}&role=${role}`)
  await dispatch(getItems({ role: getState()[SLICE_NAME].listRole }))
  return id
})

export const uploadImage = createAsyncThunk(`${SLICE_NAME}/uploadImage`, async ({ id, image }, { dispatch, getState }) => {
  const formData = new FormData()
  formData.append('image', image)

  const response = await axios.post(`${SERVER_URL}/computer-listing/${ENTITY_NAME}/upload-image/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

  return response.data
})

export const removeImage = createAsyncThunk(`${SLICE_NAME}/removeImage`, async (id, { dispatch, getState }) => {
  return id
})

export const toggleSidebar = createAsyncThunk(`${SLICE_NAME}/toggleSidebar`, async (item, { }) => {
  return item
})

export const toggleManageAsinSidebar = createAsyncThunk(`${SLICE_NAME}/toggleManageAsinSidebar`, async (item, { }) => {
  return item
})

export const toggleAsinSidebar = createAsyncThunk(`${SLICE_NAME}/toggleAsinSidebar`, async ({ item, viewMode }, { }) => {
  return { item, viewMode }
})

export const toggleAsinCalculatorSidebar = createAsyncThunk(`${SLICE_NAME}/toggleAsinCalculatorSidebar`, async (item, { }) => {
  return item
})

export const updateSelectedItem = createAsyncThunk(`${SLICE_NAME}/updateSelectedItem`, async (item, { }) => {
  return item
})

export const updateSelectedItemAsin = createAsyncThunk(`${SLICE_NAME}/updateSelectedItemAsin`, async (item, { }) => {
  return item
})

export const toggleAsinModal = createAsyncThunk(`${SLICE_NAME}/toggleAsinModal`, async (item, { }) => {
  return item
})

export const setListFilters = createAsyncThunk(`${SLICE_NAME}/setListFilters`, async (item) => {
  return item
})

export const resetListFilters = createAsyncThunk(`${SLICE_NAME}/resetListFilters`, async (item) => {
  return item
})

export const getAmazonFilters = createAsyncThunk(`${SLICE_NAME}/getAmazonFilters`, async (role) => {
  const response = await axios.get(`${SERVER_URL}/computer-listing/product/amazon?role=${role}`)

  return response.data
})

export const clearSubcategories = createAsyncThunk(`${SLICE_NAME}/clearSubcategories`, async (item) => {
  return item
})

export const toggleProductDuplicateModal = createAsyncThunk(`${SLICE_NAME}/toggleProductDuplicateModal`, async (item) => {
  return item
})

export const duplicateProduct = createAsyncThunk(`${SLICE_NAME}/duplicateProduct`, async ({ id, newSku }, { dispatch, getState }) => {
  await axios.post(`${SERVER_URL}/computer-listing/${ENTITY_NAME}/duplicate`, { id, newSku })
  await dispatch(getItems({ role: getState()[SLICE_NAME].listRole }))
  return id
})

export const forceDeleteProductAsin = createAsyncThunk(`${SLICE_NAME}/forceDeleteProductAsin`, async ({ id }, { dispatch, getState }) => {
  await axios.delete(`${SERVER_URL}/computer-listing/${ENTITY_NAME}/force-delete-asin/${id}?deleteAmazonPrice=true`)
  await dispatch(getItems({ role: getState()[SLICE_NAME].listRole }))
  return id
})

export const setRowsPerPage = createAsyncThunk(`${SLICE_NAME}/setRowsPerPage`, async (pageSize) => {
  return pageSize
})

export const setPageNo = createAsyncThunk(`${SLICE_NAME}/setPageNo`, async (pageNo) => {
  return pageNo
})

export const resetPagination = createAsyncThunk(`${SLICE_NAME}/resetPagination`, async (item) => {
  return item
})

// export const updateAndSubmitSelectedItem = createAsyncThunk(`${SLICE_NAME}/updateAndSubmitSelectedItem`, async (role, item, { dispatch, getState }) => {
//   await dispatch(updateSelectedItem(item))

//   console.log('updateAndSubmitSelectedItem', getState()[SLICE_NAME].selectedItem)

//   await dispatch(addItem(role, getState()[SLICE_NAME].selectedItem))
//   return getState()[SLICE_NAME].selectedItem
// })

const defaultInitialState = {
  list: [],
  totalList: 0,
  filters: {
    createdBy: null,
    status: null,
    categoryId: null,
    subcategoryId: null,
    amazon: null,
    search: '',
    newEgg: null
  },
  listRole: null,
  selectedItem: null,
  sidebarOpen: false,
  sidebarManageAsinOpen: false,
  sidebarAsinOpen: false,
  sidebarAsinViewMode: false,
  sidebarAsinCalculatorOpen: false,
  selectedItemListing: null,
  asinModalOpen: false,
  categoryList: [],
  amazonFilters: [],
  subcategoryList: [],
  productDuplicateModal: {
    isOpen: false,
    item: null
  },
  pagination: {
    pageNo: 0,
    pageSize: 25
  }
}

export const Slice = createSlice({
  name: SLICE_NAME,
  initialState: cloneDeep(defaultInitialState),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAccessoryList.fulfilled, (state, action) => {
        state.accessoryList = action.payload
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.categoryList = action.payload
      })
      .addCase(getSubcategories.fulfilled, (state, action) => {
        state.subcategoryList = action.payload
      })
      .addCase(getFields.fulfilled, (state, action) => {
        state.fieldList = action.payload
      })
      .addCase(getItems.fulfilled, (state, action) => {
        state.list = action.payload.list
        state.listRole = action.payload.role
        state.totalList = action.payload.totalList

        if (state.selectedItem) {
          const item = state.list.find(i => state.selectedItem.id === i.id)

          if (item) {
            state.selectedItem = { ...item, type: state.selectedItem?.type }
          }
        }
      })
      .addCase(getItem.fulfilled, (state, action) => {
        state.selectedItem = { ...action.payload, productDetails: { ...action.payload.productDetails[0] }, productListings: action.payload.productListings.length ? { ...action.payload.productListings[0] } : null }
      })
      .addCase(toggleSidebar.fulfilled, (state, action) => {
        if (state.sidebarOpen) {
          state.inventoryItems = null
          state.selectedInventoryItem = null
        }

        state.sidebarOpen = !state.sidebarOpen
        state.selectedItem = action.payload ? action.payload : cloneDeep(defaultInitialState.selectedItem)

        if (state.selectedItem) {
          state.selectedInventoryItem = {
            Image: state.selectedItem.productDetails[0].imageUrl,
            SKU: state.selectedItem.productDetails[0].sku,
            Title: state.selectedItem.productDetails[0].title,
            StockLevel: state.selectedItem.productDetails[0].stockLevel,
            StockValue: state.selectedItem.productDetails[0].stockValue,
          }
        }
      })
      .addCase(toggleManageAsinSidebar.fulfilled, (state, action) => {
        state.sidebarManageAsinOpen = !state.sidebarManageAsinOpen
        state.selectedItem = action.payload ? action.payload : cloneDeep(defaultInitialState.selectedItem)
      })
      .addCase(toggleAsinSidebar.fulfilled, (state, action) => {
        if (state.sidebarAsinOpen) {
          state.inventoryItems = null
          state.selectedInventoryItem = null
        }

        state.sidebarAsinOpen = !state.sidebarAsinOpen
        state.sidebarAsinViewMode = action.payload.viewMode
        state.sidebarManageAsinOpen = !state.sidebarManageAsinOpen
        state.selectedItemListing = action.payload.item ? action.payload.item : cloneDeep(defaultInitialState.selectedItemListing)

        if (state.selectedItemListing && state.selectedItemListing.productListingAsins?.length) {
          state.selectedInventoryItem = {
            Image: state.selectedItemListing.productListingAsins[0].imageUrl,
            SKU: state.selectedItemListing.productListingAsins[0].sku,
            Title: state.selectedItemListing.productListingAsins[0].title,
            StockLevel: state.selectedItemListing.productListingAsins[0].stockLevel,
            StockValue: state.selectedItemListing.productListingAsins[0].stockValue,
          }
        }
      })
      .addCase(toggleAsinCalculatorSidebar.fulfilled, (state, action) => {
        if (state.sidebarAsinCalculatorOpen) {
          state.inventoryItems = null
          state.selectedInventoryItem = null
        }

        state.sidebarAsinCalculatorOpen = !state.sidebarAsinCalculatorOpen
        state.selectedItemListing = action.payload ? action.payload : cloneDeep(defaultInitialState.selectedItemListing)
      })
      .addCase(toggleAsinModal.fulfilled, (state, action) => {
        state.asinModalOpen = !state.asinModalOpen
        state.selectedItemListing = action.payload ? action.payload : cloneDeep(defaultInitialState.selectedItemListing)
      })
      .addCase(updateSelectedItemAsin.fulfilled, (state, action) => {
        let productListingAsins = state.selectedItem.productListings.productListingAsins || []

        if (action.payload.id) {
          productListingAsins = state.selectedItem.productListings.productListingAsins.filter(p => p.id !== action.payload.id)
        }

        state.selectedItem = {
          ...state.selectedItem,
          productListings: { ...state.selectedItem.productListings, productListingAsins: [...productListingAsins, action.payload] }
        };

        state.selectedItemListing = null

        state.asinModalOpen = false
      })
      .addCase(uploadImage.fulfilled, (state, action) => {
        let productImages = state.selectedItem.productImages || []

        state.selectedItem = { ...state.selectedItem, productImages: [...productImages, action.payload] }
      })
      .addCase(removeImage.fulfilled, (state, action) => {
        let productImages = state.selectedItem.productImages || []
        const deletedProductImage = state.selectedItem.productImages.find(p => p.id === action.payload)
        deletedProductImage._delete = true

        productImages = state.selectedItem.productImages.filter(p => p.id !== action.payload)

        state.selectedItem = { ...state.selectedItem, productImages: [...productImages, { ...deletedProductImage }] }
      })
      .addCase(updateSelectedItem.fulfilled, (state, action) => {
        state.selectedItem = { ...action.payload }
      })
      .addCase(addItem.fulfilled, (state) => {
        state.sidebarOpen = false
      })
      .addCase(saveItem.fulfilled, (state) => {
        state.sidebarAsinOpen = false
        state.sidebarManageAsinOpen = true
        state.sidebarOpen = false
      })
      .addCase(addNote.fulfilled, (state, action) => {
        // state.selectedItem = { ...state.selectedItem };
        // state.pendingNotes.push(action.payload)
      })
      .addCase(getInventoryItemsLW.pending, (state, action) => {
        state.selectedInventoryItem = null
        state.inventoryItems = null
        state.inventoryItemsLoading = true
      })
      .addCase(getInventoryItemsLW.fulfilled, (state, action) => {
        state.inventoryItems = action.payload
        state.inventoryItemsLoading = false
      })
      .addCase(selectInventoryItem.fulfilled, (state, action) => {
        state.selectedInventoryItem = action.payload
      })
      .addCase(setListFilters.fulfilled, (state, action) => {
        state.filters = {
          ...state.filters,
          [action.payload.name]: action.payload.value
        }
        state.pagination = {
          ...state.pagination,
          pageNo: 0
        }
      })
      .addCase(resetListFilters.fulfilled, (state, action) => {
        state.filters = {
          ...defaultInitialState.filters
        }
      })
      .addCase(getAmazonFilters.fulfilled, (state, action) => {
        state.amazonFilters = action.payload.sort((a, b) => Number(a.amazon) - Number(b.amazon))
      })
      .addCase(clearSubcategories.fulfilled, (state) => {
        state.subcategoryList = []
        state.filters = {
          ...state.filters,
          subcategoryId: null
        }
      })
      .addCase(toggleProductDuplicateModal.fulfilled, (state, action) => {
        state.productDuplicateModal = {
          isOpen: !state.productDuplicateModal.isOpen,
          item: action.payload
        }
      })
      .addCase(setRowsPerPage.fulfilled, (state, action) => {
        state.pagination = {
          ...state.pagination,
          pageSize: action.payload,
          pageNo: 0
        }
      })
      .addCase(setPageNo.fulfilled, (state, action) => {
        state.pagination = {
          ...state.pagination,
          pageNo: action.payload
        }
      })
      .addCase(resetPagination.fulfilled, (state) => {
        state.pagination = {
          ...defaultInitialState.pagination
        }
      })
  }
})

export default Slice.reducer
