// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

import { SERVER_URL } from '@constants'

import { cloneDeep } from 'lodash'

const SLICE_NAME = 'CLAccessoryGroup'
const ENTITY_NAME = 'accessory-group'

export const getItems = createAsyncThunk(`${SLICE_NAME}/getItems`, async () => {
  const response = await axios.get(`${SERVER_URL}/computer-listing/${ENTITY_NAME}?includeChild=${true}`)
  return response.data
})

export const addItem = createAsyncThunk(`${SLICE_NAME}/addItem`, async (item, { dispatch }) => {
  await axios.post(`${SERVER_URL}/computer-listing/${ENTITY_NAME}`, item)
  await dispatch(getItems())
  return item
})

export const updateItem = createAsyncThunk(`${SLICE_NAME}/updateItem`, async (item, { dispatch }) => {
  await axios.patch(`${SERVER_URL}/computer-listing/${ENTITY_NAME}/${item.id}`, item)
  await dispatch(getItems())
  return item
})

export const copyItem = createAsyncThunk(`${SLICE_NAME}/copyItem`, async (item, { dispatch }) => {
  await axios.post(`${SERVER_URL}/computer-listing/${ENTITY_NAME}`, item)
  await dispatch(getItems())
  return item
})

export const deleteItem = createAsyncThunk(`${SLICE_NAME}/deleteItem`, async (id, { dispatch }) => {
  await axios.delete(`${SERVER_URL}/computer-listing/${ENTITY_NAME}/${id}`)
  await dispatch(getItems())
  return id
})

export const toggleSidebar = createAsyncThunk(`${SLICE_NAME}/toggleSidebar`, async (item, { }) => {
  return item
})

export const changeOrder = createAsyncThunk(`${SLICE_NAME}/changeOrder`, async ({ id, direction }, { dispatch, getState }) => {
    const list = getState()[SLICE_NAME].list.map(i => ({ id: i.id, order: i.order }))

    const itemIndex = list.findIndex(i => i.id === id)

    if (itemIndex > -1) {
      const newItemIndex = direction === -1 ? itemIndex - 1 : itemIndex + 1
      const item = list[itemIndex]

      if (newItemIndex > -1 && newItemIndex < list.length) {
        list.splice(itemIndex, 1)
        list.splice(newItemIndex, 0, item)

        list.forEach((item, index) => {
          item.order = index
        })

        await axios.put(`${SERVER_URL}/computer-listing/${ENTITY_NAME}/change-order`, list)
        await dispatch(getItems())
      }
    }

  return item
})

const defaultInitialState = {
  list: [],
  selectedItem: {
    name: '',
    order: '1'
  },
  sidebarOpen: false
}

export const Slice = createSlice({
  name: SLICE_NAME,
  initialState: cloneDeep(defaultInitialState),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getItems.fulfilled, (state, action) => {
        state.list = action.payload
      })
      .addCase(toggleSidebar.fulfilled, (state, action) => {
        state.sidebarOpen = !state.sidebarOpen
        state.selectedItem = action.payload ? action.payload : cloneDeep(defaultInitialState.selectedItem)
      })
      .addCase(addItem.fulfilled, (state) => {
        state.sidebarOpen = false
      })
      .addCase(updateItem.fulfilled, (state) => {
        state.sidebarOpen = false
      })
  }
})

export default Slice.reducer
