export const IS_PRODUCTION = window.location.hostname === 'hiteam.one'

export const SERVER_URL = IS_PRODUCTION ? 'https://api.hiteam.one' : 'http://localhost:4000'

export const ENABLE_TEMPLATE_DUMMY_UI = !!localStorage.getItem('ENABLE_TEMPLATE_DUMMY_UI')

export const APP_VERSION = '1.5.0'

// ==========================
//  Global
// ==========================

export const BOOLEAN_TEXT = {
    true: 'Yes',
    false: 'No'
}

export const DATE_FORMAT_SERVER = 'YYYY-MM-DD'
export const DATE_FORMAT_US = 'MM/DD/YYYY'

export const PARENT_LISTED_SKU_KEYWORD = '-LISTED'

export const ACTION_TYPES = Object.freeze({
    COMPUTER_LISTING: 'COMPUTER_LISTING',
    PARTS_LISTING: 'PARTS_LISTING',
    PARTS_LISTING_REPRICE: 'PARTS_LISTING_REPRICE',
    PRODUCTION_MANAGEMENT: 'PRODUCTION_MANAGEMENT'
})

export const PERMISSION = Object.freeze({
    CATEGORIES: Object.freeze({
        label: 'Global Categories',
        value: 'CATEGORIES'
    }),
    SETTINGS: Object.freeze({
        label: 'Global Settings',
        value: 'SETTINGS'
    }),
    USERS: Object.freeze({
        label: 'Users',
        value: 'USERS'
    }),
    ROLES: Object.freeze({
        label: 'Roles',
        value: 'ROLES'
    }),
    CONTACTS: Object.freeze({
        label: 'Contacts',
        value: 'CONTACTS'
    }),
    REPORTS: Object.freeze({
        label: 'Reports',
        value: 'REPORTS'
    }),
    RESERVES: Object.freeze({
        label: 'Reserves',
        value: 'RESERVES'
    }),
    CL_ACCESSORIES: Object.freeze({
        label: 'Computer Listing Accessories',
        value: 'CL_ACCESSORIES'
    }),
    CL_PRODUCT_CREATOR: Object.freeze({
        label: 'Computer Listing Home Work',
        value: 'CL_PRODUCT_CREATOR'
    }),
    CL_PRODUCT_LISTER: Object.freeze({
        label: 'Computer Listing Offshore Work',
        value: 'CL_PRODUCT_LISTER'
    }),
    CL_FORCE_DELETE_PRODUCT_ASIN: Object.freeze({
        label: 'Computer Listing Force Delete Product Asin',
        value: 'CL_FORCE_DELETE_PRODUCT_ASIN'
    }),
    PL_PRODUCT_CREATOR: Object.freeze({
        label: 'Parts Listing Create Product',
        value: 'PL_PRODUCT_CREATOR'
    }),
    PL_PRODUCT_LISTER: Object.freeze({
        label: 'Parts Listing List Product',
        value: 'PL_PRODUCT_LISTER'
    }),
    PL_PRODUCT_REVIEWER: Object.freeze({
        label: 'Parts Listing Review Product',
        value: 'PL_PRODUCT_REVIEWER'
    }),
    PL_REPRICE_CREATOR: Object.freeze({
        label: 'Parts Listing Create Reprice',
        value: 'PL_REPRICE_CREATOR'
    }),
    PL_REPRICE_LISTER: Object.freeze({
        label: 'Parts Listing List Reprice',
        value: 'PL_REPRICE_LISTER'
    }),
    PL_REPRICE_REVIEWER: Object.freeze({
        label: 'Parts Listing Review Reprice',
        value: 'PL_REPRICE_REVIEWER'
    }),
    PRODUCTION_MANAGEMENT: Object.freeze({
        label: 'Production Management',
        value: 'PRODUCTION_MANAGEMENT'
    }),
    PM_MANAGE_ORDER: Object.freeze({
        label: 'Production Management Manage Order',
        value: 'PM_MANAGE_ORDER'
    }),
    PM_FORCE_DELETE_ORDER: Object.freeze({
        label: 'Production Management Force Delete Order',
        value: 'PM_FORCE_DELETE_ORDER'
    }),
    PM_COMPUTER_PICKING: Object.freeze({
        label: 'Production Management Computer Picking',
        value: 'PM_COMPUTER_PICKING'
    }),
    PM_TECHNICIAN_L1: Object.freeze({
        label: 'Production Management Technician L1',
        value: 'PM_TECHNICIAN_L1'
    }),
    PM_PACKING_L1: Object.freeze({
        label: 'Production Management Packing L1',
        value: 'PM_PACKING_L1'
    }),
    PM_MONITOR_CLEANING: Object.freeze({
        label: 'Production Management Monitor Cleaning',
        value: 'PM_MONITOR_CLEANING'
    }),
    CL_PRODUCT_DUPLICATE: Object.freeze({
        label: 'Computer Listing Product Duplicate',
        value: 'CL_PRODUCT_DUPLICATE'
    }),
    PM_STACK_MANAGER: Object.freeze({
        label: 'Production Management Stack Manager',
        value: 'PM_STACK_MANAGER'
    }),
    CL_PRODUCT_LISTING_MANAGER: Object.freeze({
        label: 'Computer Listing Product Listing Manager',
        value: 'CL_PRODUCT_LISTING_MANAGER'
    })
})

export const SETTING_MODULE = {
    PARTS_LISTING: 'PARTS_LISTING',
    COMPUTER_LISTING: 'COMPUTER_LISTING',
    PRODUCTION_MANAGEMENT: 'PRODUCTION_MANAGEMENT'
}

export const SETTING_MODULE_OPTIONS = [
    {
        value: SETTING_MODULE.PARTS_LISTING,
        label: 'Parts Listing'
    },
    {
        value: SETTING_MODULE.COMPUTER_LISTING,
        label: 'Computer Listing'
    },
    {
        value: SETTING_MODULE.PRODUCTION_MANAGEMENT,
        label: 'Production Management'
    }
]

export const SETTING_KEY = {
    POSTAL_SERVICE: 'POSTAL_SERVICE',
    COMPUTER_TYPE: 'COMPUTER_TYPE',
    PROCESSOR_TYPE: 'PROCESSOR_TYPE',
    PART_TYPE: 'PART_TYPE',
    PACKAGE_TYPE: 'PACKAGE_TYPE',
    CONDITION: 'CONDITION',
    STACK_HOLD_USER_REASONS: 'STACK_HOLD_USER_REASONS',
    STACK_HOLD_MANAGER_REASONS: 'STACK_HOLD_MANAGER_REASONS',
    HOLD_ORDER_REASONS: 'HOLD_ORDER_REASONS',
    BIOS_GROUP: 'BIOS_GROUP',
    WINDOWS_GROUP: 'WINDOWS_GROUP',
    TECHNICIAN_STAGES: 'TECHNICIAN_STAGES'
}

export const SETTING_KEY_OPTIONS = [
    {
        value: SETTING_KEY.POSTAL_SERVICE,
        label: 'Postal Service'
    },
    {
        value: SETTING_KEY.COMPUTER_TYPE,
        label: 'Computer Type'
    },
    {
        value: SETTING_KEY.PROCESSOR_TYPE,
        label: 'Processor Type'
    },
    {
        value: SETTING_KEY.PART_TYPE,
        label: 'Part Type'
    },
    {
        value: SETTING_KEY.PACKAGE_TYPE,
        label: 'Package Type'
    },
    {
        value: SETTING_KEY.CONDITION,
        label: 'Condition'
    },
    {
        value: SETTING_KEY.STACK_HOLD_USER_REASONS,
        label: 'Stack Hold User Reasons'
    },
    {
        value: SETTING_KEY.STACK_HOLD_MANAGER_REASONS,
        label: 'Stack Hold Manager Reasons'
    },
    {
        value: SETTING_KEY.HOLD_ORDER_REASONS,
        label: 'Order Hold Reasons'
    },
    {
        value: SETTING_KEY.BIOS_GROUP,
        label: 'Bios Group'
    },
    {
        value: SETTING_KEY.WINDOWS_GROUP,
        label: 'Windows Group'
    },
    {
        value: SETTING_KEY.TECHNICIAN_STAGES,
        label: 'Technician Stages'
    }
]

// ==========================
//  Parts Listing
// ==========================

export const PL_FIELD_TYPE = {
    SINGLE: 'SINGLE',
    MULTIPLE: 'MULTIPLE'
}

export const PL_FIELD_TYPE_OPTIONS = [
    {
        value: PL_FIELD_TYPE.SINGLE,
        label: 'Single'
    },
    {
        value: PL_FIELD_TYPE.MULTIPLE,
        label: 'Multiple'
    }
]

export const MARKET_PLACE = {
    AMAZON: 'AMAZON',
    EBAY: 'EBAY'
}

export const MARKET_PLACE_OPTIONS = [
    {
        value: MARKET_PLACE.AMAZON,
        label: 'Amazon'
    },
    {
        value: MARKET_PLACE.EBAY,
        label: 'Ebay'
    }
]

export const PL_REPRICE_TYPE = {
    ALL: 'ALL',
    AMAZON: 'AMAZON',
    EBAY: 'EBAY',
    ASIN: 'ASIN'
}

export const PL_REPRICE_TYPE_OPTIONS = [
    {
        value: PL_REPRICE_TYPE.ALL,
        label: 'All'
    },
    {
        value: PL_REPRICE_TYPE.AMAZON,
        label: 'Amazon'
    },
    {
        value: PL_REPRICE_TYPE.EBAY,
        label: 'Ebay'
    },
    {
        value: PL_REPRICE_TYPE.ASIN,
        label: 'Asin'
    }
]

export const PL_REVIEW_OPTIONS = [
    {
        value: true,
        label: 'Approve'
    },
    {
        value: false,
        label: 'Reject'
    }
]

export const PL_USER_ROLE = {
    PRODUCT_CREATOR: 'PRODUCT_CREATOR',
    PRODUCT_LISTER: 'PRODUCT_LISTER',
    PRODUCT_REVIEWER: 'PRODUCT_REVIEWER',

    REPRICE_CREATOR: 'REPRICE_CREATOR',
    REPRICE_LISTER: 'REPRICE_LISTER',
    REPRICE_REVIEWER: 'REPRICE_REVIEWER'
}

export const PL_PRODUCT_STATUS = {
    NEW: 'NEW',
    IN_PROGRESS_L1: 'IN_PROGRESS_L1',

    PENDING_REVIEW_L1: 'PENDING_REVIEW_L1',
    UNDER_REVIEW_L1: 'UNDER_REVIEW_L1',
    ACCEPTED_L1: 'ACCEPTED_L1',
    REJECTED_L1: 'REJECTED_L1',
    REJECTED_L2: 'REJECTED_L2',

    IN_PROGRESS_L2: 'IN_PROGRESS_L2',

    PENDING_REVIEW_L2: 'PENDING_REVIEW_L2',
    UNDER_REVIEW_L2: 'UNDER_REVIEW_L2',

    COMPLETED: 'COMPLETED'
}

export const PL_PRODUCT_REPRICE_STATUS = {
    NEW: 'NEW',
    IN_PROGRESS: 'IN_PROGRESS',
    PENDING_REVIEW: 'PENDING_REVIEW',
    UNDER_REVIEW: 'UNDER_REVIEW',
    REJECTED: 'REJECTED',
    COMPLETED: 'COMPLETED'
}

// ==========================
//  Computer Listing
// ==========================

export const CL_ACCESSORY_TYPE = {
    DEFAULT: 'DEFAULT',
    MONITOR: 'MONITOR',
    SHIPPING: 'SHIPPING',
    PACKAGING: 'PACKAGING'
}

export const CL_ACCESSORY_USAGE = {
    INTERNAL: 'INTERNAL',
    EXTERNAL: 'EXTERNAL',
    EXTERNAL_AND_PACKING: 'EXTERNAL_AND_PACKING',
    PACKING_ONLY: 'PACKING_ONLY'
}

export const CL_ACCESSORY_USAGE_OPTIONS = [
    {
        value: CL_ACCESSORY_USAGE.INTERNAL,
        label: 'Internal'
    },
    {
        value: CL_ACCESSORY_USAGE.EXTERNAL,
        label: 'External'
    },
    {
        value: CL_ACCESSORY_USAGE.EXTERNAL_AND_PACKING,
        label: 'External and Packing'
    },
    {
        value: CL_ACCESSORY_USAGE.PACKING_ONLY,
        label: 'Packing Only'
    }
]

export const CL_ACCESSORY_TYPE_OPTIONS = [
    {
        value: CL_ACCESSORY_TYPE.DEFAULT,
        label: 'Default'
    },
    {
        value: CL_ACCESSORY_TYPE.MONITOR,
        label: 'Monitor'
    },
    {
        value: CL_ACCESSORY_TYPE.SHIPPING,
        label: 'Shipping'
    },
    {
        value: CL_ACCESSORY_TYPE.PACKAGING,
        label: 'Packaging'
    }
]

export const CL_USER_ROLE = {
    PRODUCT_CREATOR: 'PRODUCT_CREATOR',
    PRODUCT_LISTER: 'PRODUCT_LISTER'
}

export const CL_PRODUCT_STATUS = {
    NEW: 'NEW',
    IN_PROGRESS: 'IN_PROGRESS',
    ACCEPTED: 'ACCEPTED',
    COMPLETED: 'COMPLETED'
}

export const CL_PRODUCT_LISTING_TYPE = {
    AMAZON: 'AMAZON',
    NEW_EGG: 'NEW_EGG'
}

// ==========================
//  Production Management
// ==========================

export const PM_ORDER_STATUS = Object.freeze({
    NEW: 'NEW',
    TECHNICIAN_L1: 'TECHNICIAN_L1',
    PACKING_L1: 'PACKING_L1',
    COMPLETED: 'COMPLETED'
})

export const PM_USER_ROLE = Object.freeze({
    COMPUTER_PICKING: 'COMPUTER_PICKING',
    TECHNICIAN_L1: 'TECHNICIAN_L1',
    PACKING_L1: 'PACKING_L1',
    MONITOR_CLEANING: 'MONITOR_CLEANING'
})

export const PM_ORDER_REQUEST_STATUS = Object.freeze({
    NEW: 'NEW',
    ACCEPTED: 'ACCEPTED',
    REJECTED: 'REJECTED'
})

export const PM_ISSUE_REVIEW_OPTIONS = [
    {
        value: true,
        label: 'Approve'
    },
    {
        value: false,
        label: 'Reject'
    }
]

export const PM_ORDER_IDENTIFIER = Object.freeze({
    AMAZON_PREMIUM: 'AMAZON_PREMIUM',
    AMAZON_PRIME: 'AMAZON_PRIME',
    REPLACEMENT: 'REPLACEMENT',
    AMAZON_BUSINESS: 'AMAZON_BUSINESS'
})

export const PM_ORDER_TYPE = Object.freeze({
    AMAZON_REGULAR: 'AMAZON_REGULAR',
    AMAZON_PRIME: 'AMAZON_PRIME',
    AMAZON_PREMIUM: 'AMAZON_PREMIUM',
    AMAZON_REPLACEMENT: 'AMAZON_REPLACEMENT',
    AMAZON_BUSINESS: 'AMAZON_BUSINESS',
    NEW_EGG: 'NEW_EGG',
    EBAY: 'EBAY',
    DIRECT: 'DIRECT',
    RESEND: 'RESEND'
})

export const PM_ORDER_TYPE_TEXT = Object.freeze({
    ALL: 'All',
    AMAZON_REGULAR: 'Amazon Regular',
    AMAZON_PRIME: 'Amazon Prime',
    AMAZON_PREMIUM: 'Amazon Premium',
    AMAZON_REPLACEMENT: 'Amazon Replacement',
    AMAZON_BUSINESS: 'Amazon Business',
    NEW_EGG: 'Newegg',
    EBAY: 'Ebay',
    DIRECT: 'Direct',
    RESEND: 'Resent'
})

// ==========================
//  Reserve
// ==========================
export const RESERVE_STATUS = {
    NORMAL: {
        label: 'Normal',
        value: 'NORMAL'
    },
    LOW_STOCK: {
        label: 'Low Stock',
        value: 'LOW_STOCK'
    },
    IN_ORDER: {
        label: 'In Order',
        value: 'IN_ORDER'
    }
}

// ==========================
//  Report Types
// ==========================
export const REPORT_TYPE_LIST = [
    {
        label: 'Orders Report',
        value: 'ORDERS_REPORT'
    },
    {
        label: 'OffShore Work Report',
        value: 'LISTINGS_REPORT'
    }
]
export const REPORT_TYPES = Object.freeze({
    ORDERS_REPORT: 'ORDERS_REPORT',
    LISTINGS_REPORT: 'LISTINGS_REPORT'
})

export const PM_STACK_WORK_STATUS = Object.freeze({
    IN_PROGRESS: 'IN_PROGRESS',
    HOLD: 'HOLD',
    COMPLETED: 'COMPLETED'
})

export const STACK_NAME_JOIN_CHAR = '.'

export const PM_ORDER_EMPTY_STACK_FILTER = 'Not in any stack'
export const LIVE_TECHNICIAN_REFRESH_INTERVAL = 60 * 1000 // 1 minute
export const RESERVE_LOW_STOCK_REFRESH_INTERVAL = 60 * 1000 // 1 minute

export const PM_ORDER_WORK_STATUS = Object.freeze({
    COMPLETED: 'COMPLETED',
    DRAFT: 'DRAFT'
})

export const LINNWORKS_LOCATION = 'a17ac860-645e-48de-8f9e-b0cfe922519e';
